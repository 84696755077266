import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import AssetSummaryBreadCrumbs from './AssetSummaryBreadCrumbs';
import AssetSummaryHeaderContent from './AssetSummaryHeaderContent';
import AssetSummaryTable from './AssetSummaryTable';
import {getOrganizationIdOFLoggedUser} from '../../../../util/commonFunctions';

import {getNewAccessToken} from '../../../../redux/slices/auth/authSlice';
import {getAssetSummary, setAssetSummaryOverviewDateFilter} from '../../../../redux/slices/assets/assetSummarySlice';
import Loader from "../../../../components/commonComponents/Loader";

function AssetSummary(props) {

    const dispatch = useDispatch()

    const user = useSelector(state => state.auth.user)
    const assets = useSelector(state => state.assetSummary.assets)
    const summary_data = useSelector(state => state.assetSummary.summary_data)
    const assets_summary_table_columns = useSelector(state => state.assetSummary.assets_summary_table_columns)

    // This is for asset name search
    const asset_summary_search_filter_value = useSelector(state => state.assetSummary.assetSummarySearchFilterValue)


    // This is for quarter search
    // const assetIrrOverTimeGraphDateFilterOptions = useSelector(state => state.assetSummary.assetIrrOverTimeGraphDateFilterOptions)
    const assetSummaryOverviewDateFilterValue = useSelector(state => state.assetSummary.assetSummaryOverviewDateFilterValue)
    const dateFilterOfPortfolioPage = useSelector(state => state.portfolio.filterDate)

    const [Loading, setLoading] = useState(true)



    useEffect(() => {
        if (dateFilterOfPortfolioPage) {
            dispatch(setAssetSummaryOverviewDateFilter({assetSummaryOverviewDateFilterValue: dateFilterOfPortfolioPage}))
        }
    }, [dateFilterOfPortfolioPage, dispatch]);



    useGetAssetsList(dispatch, user, asset_summary_search_filter_value, assetSummaryOverviewDateFilterValue, setLoading)

    return (
        <>
            <div className='header-section'>
                <div className='asset-summary-page-container'>
                    <div className='asset-summary-page-header'>
                        <AssetSummaryBreadCrumbs/>
                        <AssetSummaryHeaderContent/>
                    </div>
                </div>
            </div>
            {
                Loading ? <Loader/> : null
            }

            {assets && assets_summary_table_columns && assets_summary_table_columns.length > 0 &&
                <AssetSummaryTable setSelectedAssetIdToViewAssetDetail={props.setSelectedAssetIdToViewAssetDetail}
                                   setSelectedAssetInvestmentTypeDetail={props.setSelectedAssetInvestmentTypeDetail}
                                   tableData={assets} tableHeader={assets_summary_table_columns}
                                   summaryData={summary_data}/>}
        </>
    )
}

async function getAssetsSummary(dispatch, user, retry = 0, asset_summary_search_filter_value, assetSummaryOverviewDateFilterValue,
                                setLoading) {
    const {payload} = await dispatch(
        getAssetSummary({
            organization: getOrganizationIdOFLoggedUser(user),
            assetSummarySearchFilterValue: asset_summary_search_filter_value,
            assetSummaryOverviewDateFilterValue: assetSummaryOverviewDateFilterValue,
        })
    )

    if (payload && payload.response && payload.response.status === 401 && retry < process.env.REACT_APP_MAX_RETRY_FOR_REFRESH_TOKEN) {
        await dispatch(getNewAccessToken());

        return getAssetsSummary(dispatch, user, retry + 1, asset_summary_search_filter_value, assetSummaryOverviewDateFilterValue);
    }
    else if(payload && payload.status === 200) {
        setLoading(false)
    }

    return payload
}

function useGetAssetsList(dispatch, user, asset_summary_search_filter_value, assetSummaryOverviewDateFilterValue, setLoading) {
    const isMountRef = useRef(true);
    useEffect(() => {
        if (isMountRef.current) {
            setLoading(true)
            const fetchUsers = async () => {
                const usersData = await getAssetsSummary(dispatch, user, 0, asset_summary_search_filter_value, assetSummaryOverviewDateFilterValue, setLoading);
            };

            fetchUsers();
            // isMountRef.current = false;
        }
    }, [asset_summary_search_filter_value, assetSummaryOverviewDateFilterValue]);
}

export default AssetSummary;