import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import SearchIcon from '../icons/SearchIcon';
import InputBase from '@mui/material/InputBase';
import CloseIcon from '@mui/icons-material/Close'; // Example using Material-UI's CloseIcon
import { useDispatch, useSelector } from 'react-redux';
import { setAssetSummarySearchFilter } from '../../redux/slices/assets/assetSummarySlice';
import { setUserSearchFilter } from '../../redux/slices/user/userSlice';

const Search = styled('div')(({ theme }) => ({
    display: 'flex',
    width: '18rem',
    flexDirection: 'row', // Changed to row to align the input and cross icon
    alignItems: 'center',
    gap: '0.5rem',
    position: 'relative',
    borderRadius: '0.5rem',
    border: '1px solid var(--gray-300, #D0D5DD)',
    background: 'var(--base-white, #FFF)',
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '0.375rem',
    alignSelf: 'stretch',
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    alignSelf: 'stretch',
    flexGrow: 1, // Allow input to take up remaining space
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('lg')]: {
            color: '#667085',
        },
    },
    '& .MuiInputBase-input::placeholder': {
        color: '#667085',
        opacity: 1,
    },
}));

const ClearIconWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    padding: theme.spacing(1),
    color: '#667085',
    '&:hover': {
        color: '#000', // Change color on hover
    },
}));

export default function SearchBar() {
    const dispatch = useDispatch();
    const [searchValue, setSearchValue] = useState('');

    const assetSummarySearchFilterValue = useSelector((state) => state.assetSummary.assetSummarySearchFilterValue);
    const userSearchFilterValue = useSelector((state) => state.user.userSearchFilterValue);

    // Update local state when Redux values change
    useEffect(() => {
        if (assetSummarySearchFilterValue !== '') {
            setSearchValue(assetSummarySearchFilterValue);
        } else if (userSearchFilterValue !== '') {
            setSearchValue(userSearchFilterValue);
        }
    }, [assetSummarySearchFilterValue, userSearchFilterValue]);

    const handleInputChange = (event) => {
        setSearchValue(event.target.value); // Update local state with the input value
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            dispatch(setUserSearchFilter(searchValue)); // Dispatch the action when 'Enter' key is pressed
            dispatch(setAssetSummarySearchFilter(searchValue)); // Dispatch the action when 'Enter' key is pressed
        }
    };

    const clearSearch = () => {
        setSearchValue(''); // Clear local state
        dispatch(setUserSearchFilter('')); // Clear Redux filter
        dispatch(setAssetSummarySearchFilter('')); // Clear Redux filter
    };

    return (
        <Search>
            <SearchIconWrapper>
                <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
                placeholder="Search"
                inputProps={{ 'aria-label': 'search' }}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                value={searchValue}
            />
            {searchValue && (
                <ClearIconWrapper onClick={clearSearch}>
                    <CloseIcon fontSize="small" />
                </ClearIconWrapper>
            )}
        </Search>
    );
}
