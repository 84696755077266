import React from 'react'

export default function SideBarFooterOrganizationIcon() {
    return (
        <svg className="side-bar-footer-user-icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.25008 9.16667H3.83341C3.3667 9.16667 3.13335 9.16667 2.95509 9.25749C2.79829 9.33739 2.6708 9.46487 2.59091 9.62167C2.50008 9.79993 2.50008 10.0333 2.50008 10.5V17.5M13.7501 9.16667H16.1667C16.6335 9.16667 16.8668 9.16667 17.0451 9.25749C17.2019 9.33739 17.3294 9.46487 17.4093 9.62167C17.5001 9.79993 17.5001 10.0333 17.5001 10.5V17.5M13.7501 17.5V5.16667C13.7501 4.23325 13.7501 3.76654 13.5684 3.41002C13.4086 3.09641 13.1537 2.84144 12.8401 2.68166C12.4835 2.5 12.0168 2.5 11.0834 2.5H8.91675C7.98333 2.5 7.51662 2.5 7.1601 2.68166C6.84649 2.84144 6.59153 3.09641 6.43174 3.41002C6.25008 3.76654 6.25008 4.23325 6.25008 5.16667V17.5M18.3334 17.5H1.66675M9.16675 5.83333H10.8334M9.16675 9.16667H10.8334M9.16675 12.5H10.8334"
                stroke="#667085" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}