import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from "react-router-dom";
import { setAccountSettingTabState } from '../../redux/slices/account/accountSettingSlice';
import Logout from '../../components/icons/Logout'
import { clearState } from '../../redux/slices/auth/authSlice';
import { getNewAccessToken } from '../../redux/slices/auth/authSlice';

import { logoutUser } from '../../redux/slices/auth/authSlice';

function OrganizationSettingHeader() {

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const tabState = useSelector(state => state.accountSetting.tabState)

  const toggleToProfileTab = (tabState) => {
    dispatch(setAccountSettingTabState({ tabState: tabState}))
  }

  const toggleToChangePasswordTab = (tabState) => {
    dispatch(setAccountSettingTabState({ tabState: tabState}))
  }

  return (
    <div className="account-setting-header-container">
        <div className="button-group">
            <button className={"profile-tab-button " + `${tabState && tabState === 'profile' ? 'profile-tab-active' : '' }`} onClick={ () => toggleToProfileTab('profile') }>Users</button>
            <button className={"change-password-tab-button " + `${tabState && tabState === 'change-password' ? 'change-password-tab-active' : '' }`} onClick={ () => toggleToChangePasswordTab('change-password') }>Entities</button>
        </div>

    </div>
  )
}

export default OrganizationSettingHeader