import React from 'react'

import { useDispatch, useSelector } from 'react-redux';

import AccountSettingTitle from './AccountSettingTitle'
import AccountSettingHeader from './AccountSettingHeader'
import AccountProfileSettingForm from './AccountProfileSettingForm'
import AccountPasswordWSetting from './AccountPasswordWSetting'
import User from "./User";

function AccountSetting() {
  const tabState = useSelector(state => state.accountSetting.tabState)

  return (
    <>
      <AccountSettingTitle name='Account Settings'/>
      <AccountSettingHeader />
      { tabState && tabState === 'profile' && <AccountProfileSettingForm /> }
      { tabState && tabState === 'change-password' && <AccountPasswordWSetting /> }
    </>
  )
}

export default AccountSetting