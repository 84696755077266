import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOrganizationIdOFLoggedUser } from '../../../../util/commonFunctions';
import AssetSummaryLineChart from './AssetSummaryLineChart';
import AssetIrrOverTimeGraph from './AssetIrrOverTimeGraph';
import Loader from '../../../../components/commonComponents/Loader';
import { getNewAccessToken } from '../../../../redux/slices/auth/authSlice';
import { getAssetsOverViewData } from '../../../../redux/slices/assets/assetSummarySlice';
import { getInvestmentValueOverTime } from '../../../../redux/slices/assets/assetSummarySlice';

function AssetSummaryOverView(props) {

  const dispatch = useDispatch()

  const user = useSelector(state => state.auth.user)
  const assetOverView = useSelector(state => state.assetSummary.assetOverView)
  const assetSummaryOverviewDateFilterValue = useSelector(state => state.assetSummary.assetSummaryOverviewDateFilterValue)

  const [assetOverViewLoading, setAssetOverViewLoading] = useState(true)

  useGetAssetsOverView(dispatch, props.asset_id, user, assetSummaryOverviewDateFilterValue, setAssetOverViewLoading)

  const getDisplayedValue = (value, name) =>{
    if(name === 'lifetime_mom') {
      return value.toFixed(2) + "x"
      // return value ? value + 'x': '---'
    }
    else if(name === 'lifetime_irr') {
      return value.toFixed(1) + "%"
      // return value ? value + '%': '---'
    }
    else if(name === 'lifetime_gain') {
      const formattedNumber = Math.abs(value);
      // let formattedValue = value === 0 ? '---' : value < 0 ? '-$' + formattedNumber.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0}) : '$' + formattedNumber.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0,});
      let formattedValue = value < 0 ? '-$' + formattedNumber.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0}) : '$' + formattedNumber.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0,});
      return formattedValue
    }
    else if(name === 'lifetime_realized') {
      const formattedNumber = Math.abs(value);
      // let formattedValue = value === 0 ? '0' : value < 0 ? '-$' + formattedNumber.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0}) : '$' + formattedNumber.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0});
      let formattedValue = value < 0 ? '-$' + formattedNumber.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0}) : '$' + formattedNumber.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0});
      return formattedValue
    }
    else if(name === 'ytd_irr') {
      return value.toFixed(1) + "%"
      // return value ? value + '%': '---'
    }
    else if(name === 'ytd_gain') {
      const formattedNumber = Math.abs(value);
      // let formattedValue = value === 0 ? '---' : value < 0 ? '-$' + formattedNumber.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0}) : '$' + formattedNumber.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0});
      let formattedValue = value < 0 ? '-$' + formattedNumber.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0}) : '$' + formattedNumber.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0});
      return formattedValue
    }
    else if(name === 'ytd_realized') {
      const formattedNumber = Math.abs(value);
      // let formattedValue = value === 0 ? '---' : value < 0 ? '-$' + formattedNumber.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0}) : '$' + formattedNumber.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0});
      let formattedValue = value < 0 ? '-$' + formattedNumber.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0}) : '$' + formattedNumber.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0});
      return formattedValue
    }
    else if(name === 'q1_gain') {
      const formattedNumber = Math.abs(value);
      // let formattedValue = value === 0 ? '---' : value < 0 ? '-$' + formattedNumber.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0}) : '$' + formattedNumber.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0});
      let formattedValue = value < 0 ? '-$' + formattedNumber.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0}) : '$' + formattedNumber.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0});
      return formattedValue
    }
  }

  return (
    <div className='frame-1'>
      { assetOverView && assetOverView.hasOwnProperty('asset_id') &&
        <>
          {
            assetOverViewLoading ? 
            <Loader height="15rem" />
            :
            <div className='frame-2'>
              <div className='section'>
                <div className='section-container'>
                  <div className='metric'>
                    <div className='metric-item'>
                      <span className='metric-header-text'>Lifetime MoM</span>
                      <span className='metric-header-value'>{ getDisplayedValue(assetOverView.lifetime_mom, 'lifetime_mom') }</span>
                    </div>
                  </div>
                  <div className='metric'>
                    <div className='metric-item'>
                      <span className='metric-header-text'>Lifetime IRR</span>
                      <span className='metric-header-value'>{ getDisplayedValue(assetOverView.lifetime_irr, 'lifetime_irr') }</span>
                    </div>
                  </div>
                  <div className='metric'>
                    <div className='metric-item'>
                      <span className='metric-header-text'>Lifetime Gain / (Loss)</span>
                      <span className='metric-header-value'>{ getDisplayedValue(assetOverView.lifetime_gain, 'lifetime_gain') }</span>
                    </div>
                  </div>
                  <div className='metric'>
                    <div className='metric-item'>
                      <span className='metric-header-text'>Lifetime Realized Gain / (Loss)</span>
                      <span className='metric-header-value'>{ getDisplayedValue(assetOverView.lifetime_realized, 'lifetime_realized') }</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='section '>
                <div className='section-container'>
                  <div className='metric'>
                    <div className='metric-item'>
                      <span className='metric-header-text'>YTD IRR</span>
                      <span className='metric-header-value'>{ getDisplayedValue(assetOverView.ytd_irr, 'ytd_irr') }</span>
                    </div>
                  </div>
                  <div className='metric'>
                    <div className='metric-item'>
                      <span className='metric-header-text'>YTD Gain / (Loss)</span>
                      <span className='metric-header-value'>{ getDisplayedValue(assetOverView.ytd_gain, 'ytd_gain') }</span>
                    </div>
                  </div>
                  <div className='metric'>
                    <div className='metric-item'>
                      <span className='metric-header-text'>YTD Realized Gain / (Loss)</span>
                      <span className='metric-header-value'>{ getDisplayedValue(assetOverView.ytd_realized, 'ytd_realized') }</span>
                    </div>
                  </div>
                  <div className='metric'>
                    <div className='metric-item'>
                      <span className='metric-header-text'>Selected Quarter Gain / (Loss)</span>
                      <span className='metric-header-value'>{ getDisplayedValue(assetOverView.q1_gain, 'q1_gain') }</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
          <div className='section'>
            <div className='section-container section-container-graph'>
              <div className='section-container-card'>
                <div className='section-container-card-content'>
                  <div className='section-container-card-header'>
                    <span className='graph-header-text'>Investment Value Over Time ($ in Millions)</span>
                  </div>
                  <AssetSummaryLineChart asset_id={props.asset_id} />
                </div>
              </div>
              <div className='section-container-card'>
                <div className='section-container-card-content'>
                  <AssetIrrOverTimeGraph asset_id={props.asset_id} />
                </div>
              </div>
            </div>
          </div>
        </>
      }
    </div>
  )
}

async function getAssetsOverView(dispatch, asset_id, user, assetSummaryOverviewDateFilterValue, setAssetOverViewLoading, retry = 0) {
  let params = {
    organization: getOrganizationIdOFLoggedUser(user),
    asset_id: asset_id,
    assetSummaryOverviewDateFilterValue: assetSummaryOverviewDateFilterValue,
  }

  const { payload } = await dispatch(getAssetsOverViewData(params))

  if(payload && payload.response && payload.response.status === 401 && retry < process.env.REACT_APP_MAX_RETRY_FOR_REFRESH_TOKEN) {
    await dispatch(getNewAccessToken());

    return getAssetsOverView(dispatch, asset_id, user, assetSummaryOverviewDateFilterValue, setAssetOverViewLoading, retry + 1);
  }
  else if(payload && payload.status === 200 ) {
    setAssetOverViewLoading(false)
  }

  return payload
}

function useGetAssetsOverView(dispatch, asset_id, user, assetSummaryOverviewDateFilterValue, setAssetOverViewLoading) {
  const isInitialMount = useRef(true);
  useEffect(() => {
    const fetchAssetsOverView = async () => {
      const usersData = await getAssetsOverView(dispatch, asset_id, user, assetSummaryOverviewDateFilterValue, setAssetOverViewLoading);
    };
    setAssetOverViewLoading(true)
    fetchAssetsOverView();

  }, [asset_id, assetSummaryOverviewDateFilterValue]);
}

export default AssetSummaryOverView;