import React from 'react';
import { useDispatch } from 'react-redux';

import SearchBar from '../../components/commonComponents/SearchBar';
import UserFilterIcon from '../../components/icons/FilterButtonIcon';
import AddUserIcon from '../../components/icons/AddUserIcon';

import { showAddUserModal, getUserOrganizationANdRoleList } from '../../redux/slices/user/userSlice';

export default function UserHeaderContent() {

  const dispatch = useDispatch()

  const openAddUserModal = () => {
    dispatch(getUserOrganizationANdRoleList())
    dispatch(showAddUserModal())
  }

  return (
    <div className="user-header-content">
      <span className="manage-user-text">Manage Users</span>
      <SearchBar />
      <div className="user-table-filters">
        <button className="filters-button"><UserFilterIcon />Filters</button>
        <button className="add-user-button" onClick={ openAddUserModal }><AddUserIcon />Add User</button>
      </div>
    </div>
  )
}