import React from 'react'
import {Box, ListItemButton} from "@mui/material";
import {Link, useLocation} from "react-router-dom";
import SideBarFooterUserIcon from '../icons/SideBarFooterUserIcon'
import SideBarFooterOrganizationIcon from "../icons/SideBarFooterOrganizationIcon";
import {useSelector} from "react-redux";
import adminRoutes from "../../routes/admin/adminRoutes";
import initialAdminRoutes from "../../routes/admin/initialAdminRoutes";
import userRoutes from "../../routes/user/userRoutes";
import managerRoutes from "../../routes/manager/managerRoutes";

export default function SideBarFooter() {

    const location = useLocation();
    const user = useSelector(state => state.auth.user)
    let manage_login = false
    if (localStorage.getItem('user')) {
        if (user && user.hasOwnProperty('id') && user.hasOwnProperty('role') && user.role === 3) {
            manage_login = true
        }
    }

    return (
        <Box
            display='flex'
            flexDirection='column'
            paddingTop='12px'
            alignItems='flex-start'
            gap='12px'
            alignSelf='stretch'
            borderTop='1px solid var(--gray-200, #EAECF0)'
        >

            {manage_login && (
                <ListItemButton
                    className="footer-nav-item"
                    component={Link}
                    to="my-organization"
                    selected={location.pathname === '/my-organization' ? true : false}
                    sx={{
                        display: 'flex',
                        paddingTop: '12px',
                        alignItems: 'flex-start',
                        gap: '2.9375rem',
                        alignSelf: 'stretch',
                        borderTop: '1px solid var(--gray-200, #EAECF0)',
                    }}
                >
                    <Box
                        sx={{cursor: 'pointer'}}
                        display='flex'
                        alignItems='flex-start'
                        gap='0.75rem'
                        alignSelf='stretch'
                    >
                        <SideBarFooterOrganizationIcon/>
                        <span className='side-bar-footer-accout-settings'>My organization</span>
                    </Box>
                </ListItemButton>
            )}


            <ListItemButton
                className="footer-nav-item"
                component={Link}
                to="account-setting"
                selected={location.pathname === '/account-setting' ? true : false}
                sx={{
                    display: 'flex',
                    paddingTop: '12px',
                    alignItems: 'flex-start',
                    gap: '2.9375rem',
                    alignSelf: 'stretch',
                    borderTop: '1px solid var(--gray-200, #EAECF0)',
                }}
            >
                <Box
                    sx={{cursor: 'pointer'}}
                    display='flex'
                    alignItems='flex-start'
                    gap='0.75rem'
                    alignSelf='stretch'
                >
                    <SideBarFooterUserIcon/>
                    <span className='side-bar-footer-accout-settings'>Account Settings</span>
                </Box>
            </ListItemButton>
        </Box>
    )
}