import React, { useState, useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ReactApexChart from 'react-apexcharts';
import lodash from 'lodash';
import Loader from '../../../../components/commonComponents/Loader';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AssetSummaryLineChart from '../assets/AssetSummaryLineChart';

import { getOrganizationIdOFLoggedUser } from '../../../../util/commonFunctions';
import { getNewAccessToken } from '../../../../redux/slices/auth/authSlice';
import { getYTDIRR } from '../../../../redux/slices/portfolio/portfolioSlice';
import { setExposureByCategoryViewGraph, getExposureByCategory, resetExposureByCategoryViewGraph } from '../../../../redux/slices/portfolio/portfolioSlice';
import { cloneDeep } from 'lodash';
function ExposureCategoryGraph() {
  const dispatch = useDispatch();

  const user = useSelector(state => state.auth.user)
  const dates = useSelector(state => state.portfolio.dates)
  const categories = useSelector(state => state.portfolio.categories)
  const entities = useSelector(state => state.portfolio.entities)
  const filterDate = useSelector(state => state.portfolio.filterDate)
  const filterCategory = useSelector(state => state.portfolio.filterCategory)
  const filterEntity = useSelector(state => state.portfolio.filterEntity)
  const irrOverTimeGraphSeries = useSelector(state => state.portfolio.irrOverTimeGraphSeries)
  const irrOverTimeGraphCategories = useSelector(state => state.portfolio.irrOverTimeGraphCategories)
  const exposureByCategoryGraph = useSelector(state => state.portfolio.exposureByCategoryGraph)
  const series = useSelector(state => state.portfolio.exposureByCategoryGraphSeries)
  const graphCategories = useSelector(state => state.portfolio.exposureByCategoryGraphCategories)
  const exposureByCategoryGraphLabels = useSelector(state => state.portfolio.exposureByCategoryGraphLabels)
  const exposureByCategoryGraphColors = useSelector(state => state.portfolio.exposureByCategoryGraphColors)

  const [exposureCategoryGraphLoading, setExposureCategoryGraphLoading] = useState(true)
  
  useGetExposureByCategory(dispatch, user, filterDate, filterCategory, filterEntity, dates, categories, entities, irrOverTimeGraphSeries, irrOverTimeGraphCategories, exposureByCategoryGraph, lodash, setExposureCategoryGraphLoading);
  
    const graphConfig = useMemo(() => {
        let config = {};
        if(exposureByCategoryGraph && exposureByCategoryGraph.hasOwnProperty('graphType') && exposureByCategoryGraph.hasOwnProperty('menuText') && series) {
            if(exposureByCategoryGraph.graphType === 'donut') {
                config = cloneDeep({
                    series: series,
                    options: {
                        tooltip: {
                            enabled: true,
                            y: {
                                formatter: function (val) {
                                    return (val/100).toLocaleString('en-US', {
                                      style: 'percent',
                                      minimumFractionDigits: 1,
                                      maximumFractionDigits: 1,
                                    });
                                    // return val + '%';
                                },
                            },
                        },
                        chart: {
                            width: 380,
                            type: 'donut',
                        },
                        dataLabels: {
                            enabled: false
                        },
                        responsive: [{
                            breakpoint: 480,
                            options: {
                                chart: {
                                    width: 200
                                },
                                legend: {
                                    show: false
                                }
                            }
                        }],
                        legend: {
                            position: 'right',
                            offsetY: 0,
                            height: 230,
                        },
                        colors: exposureByCategoryGraphColors,
                        labels: exposureByCategoryGraphLabels,
                    }
                });
            }
            else if(exposureByCategoryGraph.graphType === 'area' && graphCategories) {
                config = cloneDeep({
                    series: series,
                    options: {
                      chart: {
                        type: 'area',
                        stacked: false,
                        height: 350,
                        zoom: {
                          type: 'x',
                          enabled: true,
                          autoScaleYaxis: true
                        },
                        toolbar: {
                          show: false,
                        }
                      },
                      dataLabels: {
                        enabled: false
                      },
                      markers: {
                        size: 0,
                      },
                      fill: {
                        type: 'gradient',
                        gradient: {
                          shadeIntensity: 1,
                          inverseColors: false,
                          opacityFrom: 0.5,
                          opacityTo: 0,
                          stops: [0, 90, 100]
                        },
                      },
                      yaxis: {
                        title: {
                          text: 'Percentage',
                          offsetX: 10,
                        },
                        labels: {
                          formatter: function (value) {
                            const customLabels = ['0', '20%', '40%', '60%', '80%', '100%'];
            
                            const yaxisTickValues = customLabels.map((label, index) => (index / (customLabels.length - 1)) * 100);
            
                            const index = yaxisTickValues.indexOf(value);
            
                            if (index !== -1) {
                              return customLabels[index];
                            }

                            return '';
                          },
                          offsetX: 2,
                        },
                        tickAmount: 6,
                      },
                      xaxis: {
                        title: {
                          text: 'Quarter'
                        },
                        categories: graphCategories,
                      },
                      tooltip: {
                        shared: false,
                        y: {
                          formatter: function (val) {
                            return (val).toFixed(1) + '%'
                          }
                        }
                      },
                      legend: {
                        position: 'top',
                        horizontalAlign: 'right',
                      }
                    }
                });
            }
        }

        return config;
  }, [exposureByCategoryGraph, series, graphCategories]);
  
  const DropDownIcon = () => {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    }
    const handleClose = () => {
      setAnchorEl(null);
    }

    const handleSelect = (retry=0) => {
        setAnchorEl(null);
        setExposureCategoryGraphLoading(true)
        if(exposureByCategoryGraph.graphType === 'donut' && exposureByCategoryGraph.selectedFilter === 'view-donut-chart' ) {
          dispatch(setExposureByCategoryViewGraph({ graphType: 'area', menuText: 'View Donut chart', selectedFilter: 'view-over-time' }))
          dispatch(resetExposureByCategoryViewGraph())
          let params = {
            organization: getOrganizationIdOFLoggedUser(user),
            category: filterCategory ? lodash.find(categories, function(category) { return category.value === filterCategory }).label : null,
            entity: filterEntity ? filterEntity : null,
            quarter_dropdown: filterDate ? filterDate : null,
            overtime: true
          }

          dispatch(getExposureByCategory(params))
          .then(({payload}) => {
            if(payload && payload.response && payload.response.status === 401 && retry < process.env.REACT_APP_MAX_RETRY_FOR_REFRESH_TOKEN) {
              dispatch(getNewAccessToken());
              handleSelect(retry + 1);
            }
            else if(payload.status === 200) {
              setExposureCategoryGraphLoading(false)
            }
          })

        }
        else if(exposureByCategoryGraph.graphType === 'area' && exposureByCategoryGraph.selectedFilter === 'view-over-time' ) {
          dispatch(setExposureByCategoryViewGraph({ graphType: 'donut', menuText: 'View over time (% of 100)', selectedFilter: 'view-donut-chart' }))
          dispatch(resetExposureByCategoryViewGraph())
          let params = {
            organization: getOrganizationIdOFLoggedUser(user),
            category: filterCategory ? lodash.find(categories, function(category) { return category.value === filterCategory }).label : null,
            entity: filterEntity ? filterEntity : null,
            quarter_dropdown: filterDate ? filterDate : null,
            overtime: false,
          }

          dispatch(getExposureByCategory(params))
          .then(({payload}) => {
            if(payload && payload.response && payload.response.status === 401 && retry < process.env.REACT_APP_MAX_RETRY_FOR_REFRESH_TOKEN) {
              dispatch(getNewAccessToken());
              handleSelect(retry + 1);
            }
            else if(payload.status === 200) {
              setExposureCategoryGraphLoading(false)
            }
          })
        }
    }

    const fetchViewGainLossData = (retry=0) => {
        const { payload } = dispatch(getYTDIRR(
            {
                organization: getOrganizationIdOFLoggedUser(user),
                gain: true,
                quarter_dropdown: filterDate ? lodash.find(dates, function(date) { return date.value === filterDate }).label : null,
            }))
      
        if(payload && payload.response && payload.response.status === 401 && retry < process.env.REACT_APP_MAX_RETRY_FOR_REFRESH_TOKEN) {
          dispatch(getNewAccessToken());
          fetchViewGainLossData(retry + 1);
        }
    }
    
    return (
        <>
            <svg
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              className='three-dots' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M10 10.8334C10.4602 10.8334 10.8333 10.4603 10.8333 10C10.8333 9.5398 10.4602 9.16671 10 9.16671C9.53977 9.16671 9.16667 9.5398 9.16667 10C9.16667 10.4603 9.53977 10.8334 10 10.8334Z" stroke="#98A2B3" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M10 5.00004C10.4602 5.00004 10.8333 4.62694 10.8333 4.16671C10.8333 3.70647 10.4602 3.33337 10 3.33337C9.53977 3.33337 9.16667 3.70647 9.16667 4.16671C9.16667 4.62694 9.53977 5.00004 10 5.00004Z" stroke="#98A2B3" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M10 16.6667C10.4602 16.6667 10.8333 16.2936 10.8333 15.8334C10.8333 15.3731 10.4602 15 10 15C9.53977 15 9.16667 15.3731 9.16667 15.8334C9.16667 16.2936 9.53977 16.6667 10 16.6667Z" stroke="#98A2B3" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            <Menu
              id="basic-menu"
              className="view-bar-line-chart-investment-value-by-category-graph"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
              'aria-labelledby': 'basic-button',
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem onClick={ () => handleSelect() }>{ exposureByCategoryGraph.menuText }</MenuItem>
            </Menu>
        </>
    )
  }

  return (
    <div className='graph-card'>
      { exposureCategoryGraphLoading ?
        <Loader height="25rem" />
        :
        <div className='content'>
          <div className='content-header'>
            <div className='content'>
              <div className='text-view'>
                <span className='text'>Exposure By Category</span>
              </div>
              <div className='dropdown'>
                <DropDownIcon />
              </div>
            </div>
          </div>
          <div id="chart" className='investment-assets-value-by-category-view'>
            { graphConfig && graphConfig.options && exposureByCategoryGraph.graphType === 'donut' && <ReactApexChart className='investment-assets-value-by-category-chart' options={graphConfig.options} series={graphConfig.series} type='donut' height={300} /> }
            { graphConfig && graphConfig.options && exposureByCategoryGraph.graphType === 'area' && <ReactApexChart className='investment-assets-value-by-category-chart' options={graphConfig.options} series={graphConfig.series} type='area' height={300} /> }
          </div>
        </div>
      }
    </div>
  )
}

function useGetExposureByCategory(dispatch, user, filterDate, filterCategory, filterEntity, dates, categories, entities, irrOverTimeGraphSeries, irrOverTimeGraphCategories, exposureByCategoryGraph, lodash, setExposureCategoryGraphLoading) {
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (irrOverTimeGraphSeries && irrOverTimeGraphSeries.length && irrOverTimeGraphCategories && irrOverTimeGraphCategories && isInitialMount.current) {
      fetchData(dispatch, user)
      isInitialMount.current = false;
    }
  }, [dispatch, user, irrOverTimeGraphSeries, irrOverTimeGraphCategories]);

  useEffect(() => {
    if (irrOverTimeGraphSeries && irrOverTimeGraphSeries.length && irrOverTimeGraphCategories && irrOverTimeGraphCategories) {
      setExposureCategoryGraphLoading(true)
      fetchData(dispatch, user)
    }
  }, [dispatch, user, filterDate, filterCategory, filterEntity]);

  async function fetchData(dispatch, user, retry=0) {
    let params = {
        organization: getOrganizationIdOFLoggedUser(user),
        quarter_dropdown: filterDate ? lodash.find(dates, function(date) { return date.value === filterDate }).label : null,
        category: filterCategory ? lodash.find(categories, function(category) { return category.value === filterCategory }).label : null,
        entity: filterEntity ? filterEntity : null,
        overtime: exposureByCategoryGraph.selectedFilter === 'view-over-time' ? true : false,
    }

    const { payload } = await dispatch(getExposureByCategory(params))

    if(payload && payload.response && payload.response.status === 401 && retry < process.env.REACT_APP_MAX_RETRY_FOR_REFRESH_TOKEN) {
      dispatch(getNewAccessToken());
      fetchData(dispatch, user, retry + 1);
    }
    else if(payload && payload.status === 200) {
      setExposureCategoryGraphLoading(false)
    }
  }
}

export default ExposureCategoryGraph