import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import { Modal, Typography } from "@mui/material";
import ModalCloseIcon from '../icons/ModalCloseIcon';

import { getNewAccessToken } from '../../redux/slices/auth/authSlice';
import {deleteEntity, deleteUser} from '../../redux/slices/user/userSlice';
import { hideConfirmationAlertModal } from '../../redux/slices/alertSlice';
import { showConfirmationAlertModal } from '../../redux/slices/alertSlice';
import { deleteOrganization } from '../../redux/slices/organization/organizationSlice';
import {
    deleteAsset,
    deleteAssetEvent,
    getAssetEvents,
    getAssetSummary
} from '../../redux/slices/assets/assetSummarySlice';
import { getOrganizationIdOFLoggedUser } from '../../util/commonFunctions';
import { getOrganizationEvents } from '../../redux/slices/eventdatabase/eventDataBaseSlice';


function ConfirmationAlertModal() {

    const dispatch = useDispatch();

    const confirmationAlertModalShown = useSelector(state => state.alert.confirmationAlertModalShown)
    const user = useSelector(state => state.auth.user)
    const alertTitle = useSelector(state => state.alert.alertTitle)
    const alertMessage = useSelector(state => state.alert.alertMessage)
    const alertType = useSelector(state => state.alert.alertType)
    const removing_id = useSelector(state => state.alert.removing_id)
    const error = useSelector(state => state.alert.error)
    const asset_id = useSelector(state => state.alert.asset_id)
    const screen = useSelector(state => state.alert.screen)

    const closeAlertModal = () => {
        dispatch(hideConfirmationAlertModal())
    }

    const submitAlertModal = (values, retry=0) => {
        if(alertType === 'remove_user') {
            dispatch(deleteUser(values))
            .then(({payload}) => {
                if(payload && payload.status === 200 ) {
                    closeAlertModal()
                }
                else if(payload && payload.status !== 200 && payload.response && payload.response.status === 401 && retry < process.env.REACT_APP_MAX_RETRY_FOR_REFRESH_TOKEN) {
                    dispatch(getNewAccessToken())
                    .then((res) => {
                        if(res.payload && res.payload.data && res.payload.data.success) {
                            return submitAlertModal(values, retry + 1)
                        }
                    })
                }
            })
        }
        else if(alertType === 'remove_entity') {
            dispatch(deleteEntity(values))
            .then(({payload}) => {
                if(payload && payload.status === 200 ) {
                    closeAlertModal()
                }
                else if(payload && payload.status !== 200 && payload.response && payload.response.status === 401 && retry < process.env.REACT_APP_MAX_RETRY_FOR_REFRESH_TOKEN) {
                    dispatch(getNewAccessToken())
                    .then((res) => {
                        if(res.payload && res.payload.data && res.payload.data.success) {
                            return submitAlertModal(values, retry + 1)
                        }
                    })
                }
            })
        }
        else if(alertType === 'remove_organization') {
            dispatch(deleteOrganization(values))
            .then(({payload}) => {
                if(payload && payload.status === 200 ) {
                    closeAlertModal()
                }
                else if(payload && payload.status !== 200 && payload.response && payload.response.status === 401 && retry < process.env.REACT_APP_MAX_RETRY_FOR_REFRESH_TOKEN) {
                    dispatch(getNewAccessToken())
                    .then((res) => {
                        if(res.payload && res.payload.data && res.payload.data.success) {
                            return submitAlertModal(values, retry + 1)
                        }
                    })
                }
                else if (payload && payload.status !== 200 && payload.response && payload.response.status === 400) {
                    let alert_params = {
                      alertTitle: alertTitle,
                      alertMessage: alertMessage,
                      alertType: alertMessage,
                      removing_id: removing_id,
                      error: payload.response.data
                    }
                    dispatch(showConfirmationAlertModal(alert_params))
                }
            })
        }
        else if(alertType === 'remove_asset') {
            // dispatch(deleteOrganization(values))
            values.organization = getOrganizationIdOFLoggedUser(user)
            console.log(values)
            dispatch(deleteAsset(values))
            .then(({payload}) => {
                if(payload && payload.status === 200 ) {
                    dispatch(getAssetSummary({ organization: getOrganizationIdOFLoggedUser(user) }))
                    closeAlertModal()
                }
                else if(payload && payload.status !== 200 && payload.response && payload.response.status === 401 && retry < process.env.REACT_APP_MAX_RETRY_FOR_REFRESH_TOKEN) {
                    dispatch(getNewAccessToken())
                    .then((res) => {
                        if(res.payload && res.payload.data && res.payload.data.success) {
                            return submitAlertModal(values, retry + 1)
                        }
                    })
                }
                else if (payload && payload.status !== 200 && payload.response && payload.response.status === 400) {
                    let alert_params = {
                      alertTitle: alertTitle,
                      alertMessage: alertMessage,
                      alertType: alertMessage,
                      removing_id: removing_id,
                      error: payload.response.data
                    }
                    dispatch(showConfirmationAlertModal(alert_params))
                }
            })
        }
        else if(alertType === 'remove_event') {
            let params = { 
                id: removing_id,
                organization: getOrganizationIdOFLoggedUser(user),
                asset_id: asset_id,
            }

            console.log('params ', params)
            dispatch(deleteAssetEvent(params))
            .then(({payload}) => {
                if(payload && payload.status === 200 ) {                    
                    if(screen === 'asset_summary') {
                        if (payload.data && payload.data["reload_page"]) {
                            closeAlertModal()
                            window.location.reload()
                        }
                        else {
                            dispatch(getAssetEvents({ organization: getOrganizationIdOFLoggedUser(user), asset_id: asset_id }))
                            closeAlertModal()
                        }
                    }
                    else if(screen === 'event') {
                        console.log('here i am')
                        dispatch(getOrganizationEvents({ organization: getOrganizationIdOFLoggedUser(user) }))
                        closeAlertModal()
                    }
                }
                else if(payload && payload.status !== 200 && payload.response && payload.response.status === 401 && retry < process.env.REACT_APP_MAX_RETRY_FOR_REFRESH_TOKEN) {
                    dispatch(getNewAccessToken())
                    .then((res) => {
                        if(res.payload && res.payload.data && res.payload.data.success) {
                            return submitAlertModal(values, retry + 1)
                        }
                    })
                }
            })
        }

    }

    const TrashIcon = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M16 6V5.2C16 4.0799 16 3.51984 15.782 3.09202C15.5903 2.71569 15.2843 2.40973 14.908 2.21799C14.4802 2 13.9201 2 12.8 2H11.2C10.0799 2 9.51984 2 9.09202 2.21799C8.71569 2.40973 8.40973 2.71569 8.21799 3.09202C8 3.51984 8 4.0799 8 5.2V6M10 11.5V16.5M14 11.5V16.5M3 6H21M19 6V17.2C19 18.8802 19 19.7202 18.673 20.362C18.3854 20.9265 17.9265 21.3854 17.362 21.673C16.7202 22 15.8802 22 14.2 22H9.8C8.11984 22 7.27976 22 6.63803 21.673C6.07354 21.3854 5.6146 20.9265 5.32698 20.362C5 19.7202 5 18.8802 5 17.2V6" stroke="#D92D20" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        )
    }

    return (
        <Modal
            open={confirmationAlertModalShown}
            onClose={ closeAlertModal }
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="confirmation-alert-modal-view"
        >
            <div className="confirmation-alert-modal">
                <div className="confirmation-alert-modal-header">
                    <div className="confirmation-alert-modal-header-content">
                        <div className="confirmation-alert-modal-header-icons-view">
                            <div className='d-flex'>
                                <div className="confirmation-alert-modal-header-feature-icon me-3">
                                    <TrashIcon />
                                </div>
                                <div className="confirmation-alert-modal-header-text">
                                    <Typography className="confirmation-alert-modal-header-title-text">{ alertTitle }</Typography>
                                    <Typography className="confirmation-alert-modal-header-info-text">{ alertMessage }</Typography>
                                </div>
                            </div>
                            <div className="confirmation-alert-modal-header-close-button">
                                <ModalCloseIcon closeModal={ closeAlertModal } />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="confirmation-alert-modal-content">
                    <Formik
                        initialValues={{
                            id: removing_id
                        }}
                        onSubmit={(values) => {
                            submitAlertModal(values)
                        }}
                    >
                        {({ errors, values, handleChange, handleSubmit, setFieldValue }) => (
                            <Form className="confirmation-alert-form">
                                <div className="confirmation-alert-modal-footer">
                                    <div className="confirmation-alert-modal-footer-content">
                                        { error && <div className="error">{ error }</div>}
                                        <div className="confirmation-alert-modal-footer-buttons">
                                            <button type="button" onClick={ closeAlertModal } className="cancel-user-button">Cancel</button>
                                            <button type="submit" className='enable-confirmation-alert-button'>Delete</button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </Modal>
    )
}

export default ConfirmationAlertModal