import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import PortfolioStat from './PortfolioStat'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Loader from '../../../../components/commonComponents/Loader';

import { getOrganizationIdOFLoggedUser } from '../../../../util/commonFunctions';
import { getNewAccessToken } from '../../../../redux/slices/auth/authSlice';
import { getAllTimeIRR, setAllTimeIrrMenuOptions } from '../../../../redux/slices/portfolio/portfolioSlice';

function AllTimeIRR(props) {

    const dispatch = useDispatch();

    const user = useSelector(state => state.auth.user)
    const ytd_irr = useSelector(state => state.portfolio.ytd_irr)
    const all_time_irr = useSelector(state => state.portfolio.all_time_irr)
    const filterEntity = useSelector(state => state.portfolio.filterEntity)
    const filterDate = useSelector(state => state.portfolio.filterDate)
    const all_time_irr_menu_options = useSelector(state => state.portfolio.all_time_irr_menu_options)

    const [allTimeIRRLoading, setAllTimeIRRLoading] = useState(true)

    useGetYTDIRR(dispatch, user, ytd_irr, all_time_irr_menu_options, filterEntity, filterDate, setAllTimeIRRLoading);

    const DropDownIcon = () => {

        const [anchorEl, setAnchorEl] = useState(null);
        const open = Boolean(anchorEl);

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        }
        const handleClose = () => {
            setAnchorEl(null);
        }

        const handleSelect = (value) => {
            setAnchorEl(null);
            if(all_time_irr_menu_options && all_time_irr_menu_options.selected_item === 'all-time-irr') {
                dispatch(setAllTimeIrrMenuOptions({ menuText: 'View all time IRR', selected_item: 'view-gain-loss' }))
                fetchViewGainLossData(true)
            }
            else if(all_time_irr_menu_options && all_time_irr_menu_options.selected_item === 'view-gain-loss') {
                dispatch(setAllTimeIrrMenuOptions({ menuText: 'View gain/loss', selected_item: 'all-time-irr' }))
                fetchViewGainLossData(false)
            }
        }

        const fetchViewGainLossData = (gain, retry=0) => {
            const { payload } = dispatch(getAllTimeIRR(
                {
                    organization: getOrganizationIdOFLoggedUser(user),
                    gain: gain,
                    entity: filterEntity,
                    quarter_dropdown: filterDate
                }))
  
            if(payload && payload.response && payload.response.status === 401 && retry < process.env.REACT_APP_MAX_RETRY_FOR_REFRESH_TOKEN) {
                dispatch(getNewAccessToken());
                fetchViewGainLossData(gain, retry + 1);
            }
        }
        
        return (
            <>
                <svg
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    className='three-dots' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M10 10.8334C10.4602 10.8334 10.8333 10.4603 10.8333 10C10.8333 9.5398 10.4602 9.16671 10 9.16671C9.53977 9.16671 9.16667 9.5398 9.16667 10C9.16667 10.4603 9.53977 10.8334 10 10.8334Z" stroke="#98A2B3" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M10 5.00004C10.4602 5.00004 10.8333 4.62694 10.8333 4.16671C10.8333 3.70647 10.4602 3.33337 10 3.33337C9.53977 3.33337 9.16667 3.70647 9.16667 4.16671C9.16667 4.62694 9.53977 5.00004 10 5.00004Z" stroke="#98A2B3" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M10 16.6667C10.4602 16.6667 10.8333 16.2936 10.8333 15.8334C10.8333 15.3731 10.4602 15 10 15C9.53977 15 9.16667 15.3731 9.16667 15.8334C9.16667 16.2936 9.53977 16.6667 10 16.6667Z" stroke="#98A2B3" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <Menu
                    id="basic-menu"
                    className="all-time-view-gain-loss-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                >
                    <MenuItem onClick={ () => handleSelect() }>{ all_time_irr_menu_options.menuText }</MenuItem>
                </Menu>
            </>
        )
    }

    const getHeaderText = (value) => {
        switch (value) {
            case 'view-gain-loss':
                return 'Lifetime gain/loss';
            case 'all-time-irr':
                return 'Lifetime IRR';
            default:
                return 'Lifetime IRR';
          }
    }

    return (
        allTimeIRRLoading ?
        <Loader width="25%" />
        :
        <div className='metric-item'>
            <div className='header'>
                <span className='title'>{ getHeaderText(all_time_irr_menu_options.selected_item) }</span>
                <div className='dropdown'>
                    <DropDownIcon />
                </div>
            </div>
            { all_time_irr && all_time_irr.hasOwnProperty('value') && <PortfolioStat show={ all_time_irr_menu_options.selected_item === 'view-gain-loss' } irrType={props.irrType} stat={all_time_irr} /> }

        </div>
    )
}

function useGetYTDIRR(dispatch, user, ytd_irr, all_time_irr_menu_options, filterEntity, filterDate, setAllTimeIRRLoading) {
    const isInitialMount = useRef(true);
    useEffect(() => {
        if (ytd_irr && ytd_irr.hasOwnProperty('increase') && ytd_irr.hasOwnProperty('percentage') && ytd_irr.hasOwnProperty('value')) {
            fetchData(dispatch, user)
            isInitialMount.current = false;
        }
    }, [dispatch, user, ytd_irr]);
    
    useEffect(() => {
        if (ytd_irr && ytd_irr.hasOwnProperty('increase') && ytd_irr.hasOwnProperty('percentage') && ytd_irr.hasOwnProperty('value')) {
            setAllTimeIRRLoading(true)
            fetchData(dispatch, user)
        }
    }, [dispatch, user, filterEntity, filterDate]);

    async function fetchData(dispatch, user, retry=0) {
        let params  = {
            organization: getOrganizationIdOFLoggedUser(user),
            gain: all_time_irr_menu_options.selected_item === 'view-gain-loss' ? true : false,
            entity: filterEntity,
            quarter_dropdown: filterDate
        }

        const { payload } = await dispatch(getAllTimeIRR(params))
  
        if(payload && payload.response && payload.response.status === 401 && retry < process.env.REACT_APP_MAX_RETRY_FOR_REFRESH_TOKEN) {
            dispatch(getNewAccessToken());
            fetchData(dispatch, user, retry + 1);
        }
        else if(payload && payload.status === 200) {
            setAllTimeIRRLoading(false)
        }

    }
}

export default AllTimeIRR