import React, {useState, useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import ReactApexChart from 'react-apexcharts';
import Loader from '../../../../components/commonComponents/Loader';
import {getOrganizationIdOFLoggedUser} from '../../../../util/commonFunctions';
import {getNewAccessToken} from '../../../../redux/slices/auth/authSlice';
import {getInvestmentValueOverTime} from '../../../../redux/slices/assets/assetSummarySlice';
import {cloneDeep} from "lodash";

const AssetSummaryLineChart = (props) => {

    const dispatch = useDispatch()

    const user = useSelector(state => state.auth.user)
    const quarters = useSelector(state => state.assetSummary.quarters)
    const series = cloneDeep(useSelector(state => state.assetSummary.series))
    const assetSummaryOverviewDateFilterValue = useSelector(state => state.assetSummary.assetSummaryOverviewDateFilterValue)

    const [assetSummaryLineChartLoading, setAssetSummaryLineChartLoading] = useState(true)

    useGetAssetsOverGraphData(dispatch, props.asset_id, user, assetSummaryOverviewDateFilterValue, setAssetSummaryLineChartLoading)

    let maxDataValue = 5
    if (Array.isArray(series) && series.length > 0 && series[0] && series[0]['data']) {
        maxDataValue = Math.max(...series[0]['data']);
        maxDataValue = maxDataValue + (maxDataValue/2)
        // if (maxDataValue % 5 !== 0) {
        //     maxDataValue = Math.ceil(maxDataValue / 5) * 5;
        // }

    }


    // const options = {
    //     chart: {
    //         type: 'area',
    //         stacked: false,
    //         height: 350,
    //         zoom: {
    //             type: 'x',
    //             enabled: true,
    //             autoScaleYaxis: true
    //         },
    //         toolbar: {
    //             show: false,
    //         }
    //     },
    //     dataLabels: {
    //         enabled: false
    //     },
    //     markers: {
    //         size: 0,
    //     },
    //     fill: {
    //         type: 'gradient',
    //         gradient: {
    //             shadeIntensity: 1,
    //             inverseColors: false,
    //             opacityFrom: 0.5,
    //             opacityTo: 0,
    //             stops: [0, 90, 100]
    //         },
    //     },
    //     yaxis: {
    //         title: {
    //             text: 'Amount'
    //         },
    //         labels: {
    //             formatter: function (value, index) {
    //                 if (value === 0) {
    //                     return '0';
    //                 } else {
    //                     return value.toFixed(0) + 'M';
    //                 }
    //             },
    //         },
    //         tickAmount: 5,
    //         min: 0,
    //         max: maxDataValue,
    //     },
    //     xaxis: {
    //         type: 'category',
    //         title: {
    //             text: 'Month'
    //         },
    //         labels: {
    //             show: true,
    //         },
    //         categories: quarters,
    //     },
    //     tooltip: {
    //         shared: false,
    //         y: {
    //             formatter: function (val) {
    //                 return (val).toFixed(2)
    //             }
    //         }
    //     },
    //     legend: {
    //         position: 'top',
    //         horizontalAlign: 'right',
    //     },
    // }


    const optionzs = {
        series: series,
        chart: {
            height: 350,
            type: 'line',
            toolbar: {
                show: false,
            }
        },
        stroke: {
            width: [0, 4]
        },
        dataLabels: {
            enabled: false,
            enabledOnSeries: [1],

        },
        labels: quarters,
        yaxis: [{
            labels: {
                formatter: function (value, index) {
                    if (value === 0) {
                        return '0';
                    } else {
                        return value.toFixed(1) + 'M';
                    }
                },
            },
            tickAmount: 4,
            min: 0,
            max: maxDataValue,
        }
        ]
    };

    return (
        (
            assetSummaryLineChartLoading ?
                <Loader height='15rem'/>
                :
                <div id="chart" className='overview-chart-view'>
                    <ReactApexChart className='overview-chart' options={optionzs} series={series} type="area"
                                    height={350}/>
                </div>
        )
    );

};


async function getAssetsOverViewGraphData(dispatch, asset_id, user, assetSummaryOverviewDateFilterValue, setAssetSummaryLineChartLoading, retry = 0) {
    let params = {
        organization: getOrganizationIdOFLoggedUser(user),
        asset_id: asset_id,
        from_date: null,
        end_date: null,
        assetSummaryOverviewDateFilterValue: assetSummaryOverviewDateFilterValue,
    }

    const {payload} = await dispatch(getInvestmentValueOverTime(params))

    if (payload && payload.response && payload.response.status === 401 && retry < process.env.REACT_APP_MAX_RETRY_FOR_REFRESH_TOKEN) {
        await dispatch(getNewAccessToken());

        return getAssetsOverViewGraphData(dispatch, asset_id, user, assetSummaryOverviewDateFilterValue, setAssetSummaryLineChartLoading, retry + 1);
    } else if (payload && payload.status === 200) {
        setAssetSummaryLineChartLoading(false)
    }

    return payload
}

function useGetAssetsOverGraphData(dispatch, asset_id, user, assetSummaryOverviewDateFilterValue, setAssetSummaryLineChartLoading) {
    const isInitialMount = useRef(true);
    useEffect(() => {
        // This block will execute when the component re-renders due to asset_id changes
        const fetchAssetsOverViewGraph = async () => {
            const usersData = await getAssetsOverViewGraphData(dispatch, asset_id, user, assetSummaryOverviewDateFilterValue, setAssetSummaryLineChartLoading);
        };
        setAssetSummaryLineChartLoading(true)
        fetchAssetsOverViewGraph();
    }, [asset_id, assetSummaryOverviewDateFilterValue]);
}

export default AssetSummaryLineChart;
