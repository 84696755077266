import React from 'react'

import { useDispatch, useSelector } from 'react-redux';

import AccountSettingTitle from './AccountSettingTitle'
import OrganizationSettingForm from "./OrganizationSettingForm";
import User from "./User";
import OrganizationSettingHeader from "./OrganizationSettingHeader";
import Entity from "./EntityTab/Entity";

function MyOrganization() {
  const tabState = useSelector(state => state.accountSetting.tabState)

  return (
    <>
      <AccountSettingTitle name='My Organization'/>
      <OrganizationSettingForm />
      <OrganizationSettingHeader />
      { tabState && tabState === 'profile' && <User /> }
      { tabState && tabState === 'change-password' && <Entity /> }
    </>
  )
}

export default MyOrganization