import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../../components/commonComponents/Loader';
import { getOrganizationIdOFLoggedUser } from '../../../../util/commonFunctions';

import { getNewAccessToken } from '../../../../redux/slices/auth/authSlice';
import { getAssetDetails } from '../../../../redux/slices/assets/assetSummarySlice';
import SimpleDatePicker from "../../../../components/commonComponents/SimpleDatePicker";

function AssetDetails(props) {

  const dispatch = useDispatch()

  const user = useSelector(state => state.auth.user)
  const assetsWithDetails = useSelector(state => state.assetSummary.assetsWithDetails)
  const assetSummaryOverviewDateFilterValue = useSelector(state => state.assetSummary.assetSummaryOverviewDateFilterValue)

  const [assetsWithDetailsLoading, setAssetsWithDetailsLoading] = useState(true)

  useGetAssetsDetails(dispatch, props.asset_id, user, setAssetsWithDetailsLoading, assetSummaryOverviewDateFilterValue)

  const organization_id = getOrganizationIdOFLoggedUser(user)


  const getDisplayedValue = (value, name) =>{
    if(name === 'investment') {
      return value ? 'Investment' : 'Non investment'
    }
    else if(name === 'sponsor') {
      return value ? value : '---'
    }
    else if(name === 'asset_class') {
      return value ? value : '---'
    }
    else if(name === 'sub_asset_class') {
      return value ? value : '---'
    }
    else if(name === 'sector') {
      return value ? value : '---'
    }
    else if(name === 'industry_group') {
      return value ? value : '---'
    }
    else if(name === 'investment_type') {
      return value ? value : '---'
    }
    else if(name === 'investment_position') {
      return value ? value : '---'
    }
    else if(name === 'region') {
      return value ? value : '---'
    }
    else if(name === 'state') {
      return value ? value : '---'
    }
    else if(name === 'vintage') {
      return value ? value : '---'
    }
    else if(name === 'entry_date') {
      return value ? value : '---'
    }
    else if(name === 'exit_date') {
      return value ? value : '---'
    }
    else if(name === 'commitment') {
      const formattedNumber = Math.abs(value);
      // let formattedValue = value === 0 ? '---' : value < 0 ? '-$' + formattedNumber.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0}) : '$' + formattedNumber.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0});
      let formattedValue = value < 0 ? '-$' + formattedNumber.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0}) : '$' + formattedNumber.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0});
      return formattedValue
    }
    else if(name === 'invested') {
      const formattedNumber = Math.abs(value);
      // let formattedValue = value === 0 ? '---' : value < 0 ? '-$' + formattedNumber.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0}) : '$' + formattedNumber.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0});
      let formattedValue = value < 0 ? '-$' + formattedNumber.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0}) : '$' + formattedNumber.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0});
      return formattedValue
    }
    else if(name === 'returned') {
      const formattedNumber = Math.abs(value);
      // let formattedValue = value === 0 ? '---' : value < 0 ? '-$' + formattedNumber.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0}) : '$' + formattedNumber.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0});
      let formattedValue = value < 0 ? '-$' + formattedNumber.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0}) : '$' + formattedNumber.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0});
      return formattedValue
    }
    else if(name === 'current_value') {
      const formattedNumber = Math.abs(value);
      // let formattedValue = value === 0 ? '---' : value < 0 ? '-$' + formattedNumber.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0}) : '$' + formattedNumber.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0});
      let formattedValue = value < 0 ? '-$' + formattedNumber.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0}) : '$' + formattedNumber.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0});
      return formattedValue
    }
  }

  const handleDateChange = (newDate) => {
    console.log('Selected Date:', newDate);
    // Implement your logic to update the date, perhaps making an API call
  };



  return (
    (
    assetsWithDetailsLoading ?
      <Loader height="40rem" />
      :
    assetsWithDetails && assetsWithDetails.hasOwnProperty('id') &&
      <div className='asset-details-table'>
        <div className='content'>
          <div className='column-1'>
            <span className='cell'>Asset Type</span>
            <span className='cell'>Manager/Sponsor</span>
            <span className='cell'>Asset Class</span>
            <span className='cell'>Sub-Asset Class</span>
            <span className='cell'>Sector</span>
            <span className='cell'>Industry Group</span>
            <span className='cell'>Investment Type</span>
            <span className='cell'>Investment Position</span>
            {/*<span className='cell'>Vintage</span>*/}
            <span className='cell'>Region</span>
            <span className='cell'>State</span>
            <span className='cell'>Entry Date</span>
            <span className='cell'>Exit Date</span>
            {user.role !== 4 && <span className='cell'>Total Commitment</span>}
            <span className='cell'>Invested Capital</span>
            <span className='cell'>Capital Returned</span>
            <span className='cell'>Current Remaining Value</span>
          </div>
          <div className='column-2'>
            <span className='cell'>{ getDisplayedValue(assetsWithDetails.investment, 'investment') }</span>
            <span className='cell'>{ getDisplayedValue(assetsWithDetails.sponsor, 'sponsor') }</span>
            <span className='cell'>{ getDisplayedValue(assetsWithDetails.asset_class, 'asset_class') }</span>
            <span className='cell'>{ getDisplayedValue(assetsWithDetails.sub_asset_class, 'sub_asset_class') }</span>
            <span className='cell'>{ getDisplayedValue(assetsWithDetails.sector, 'sector') }</span>
            <span className='cell'>{ getDisplayedValue(assetsWithDetails.industry_group, 'industry_group') }</span>
            <span className='cell'>{ getDisplayedValue(assetsWithDetails.investment_type, 'investment_type') }</span>
            <span className='cell'>{ getDisplayedValue(assetsWithDetails.investment_position, 'investment_position') }</span>
            <span className='cell'>{ getDisplayedValue(assetsWithDetails.region, 'region') }</span>
            <span className='cell'>{ getDisplayedValue(assetsWithDetails.state, 'state') }</span>
            <span className='cell'>{ getDisplayedValue(assetsWithDetails.entry_date, 'entry_date') }</span>
            <span className='cell'>{ getDisplayedValue(assetsWithDetails.exit_date, 'entry_date') }</span>

            {/*<span className='cell'>*/}
            {/*  <div className="date-picker-container">*/}
            {/*    <SimpleDatePicker organizationId={organization_id} assetId={ props.asset_id} selectedDate={assetsWithDetails.exit_date} onDateChange={handleDateChange} />*/}
            {/*  </div>*/}
            {/*</span>*/}

            {/*<span className='cell'>{ getDisplayedValue(assetsWithDetails.vintage, 'vintage') }</span>*/}
            {user.role !== 4 && <span className='cell'>{ getDisplayedValue(assetsWithDetails.commitment, 'commitment') }</span>}
            <span className='cell'>{ getDisplayedValue(assetsWithDetails.invested, 'invested') }</span>
            <span className='cell'>{ getDisplayedValue(assetsWithDetails.returned, 'returned') }</span>
            <span className='cell'>{ getDisplayedValue(assetsWithDetails.current_value, 'current_value') }</span>


          </div>
        </div>
      </div>
    )
  )
}

async function getAssetsDetails(dispatch, asset_id, user, setAssetsWithDetailsLoading, retry = 0, assetSummaryOverviewDateFilterValue) {
  let params = {
    organization: getOrganizationIdOFLoggedUser(user),
    asset_id: asset_id,
    assetSummaryOverviewDateFilterValue: assetSummaryOverviewDateFilterValue
  }

  const { payload } = await dispatch(getAssetDetails(params))

  if(payload && payload.response && payload.response.status === 401 && retry < process.env.REACT_APP_MAX_RETRY_FOR_REFRESH_TOKEN) {
    await dispatch(getNewAccessToken());

    // Retry the "get organization data" call with the new token
    return getAssetsDetails(dispatch, asset_id, user, setAssetsWithDetailsLoading, retry + 1, assetSummaryOverviewDateFilterValue);
  }
  else if(payload && payload.status === 200) {
    setAssetsWithDetailsLoading(false)
  }

  return payload
}

function useGetAssetsDetails(dispatch, asset_id, user, setAssetsWithDetailsLoading, assetSummaryOverviewDateFilterValue) {
  const isInitialMount = useRef(true);
  useEffect(() => {
    // This block will execute when the component re-renders due to asset_id changes
    const fetchAssetsDetails = async () => {
      const usersData = await getAssetsDetails(dispatch, asset_id, user, setAssetsWithDetailsLoading, 0, assetSummaryOverviewDateFilterValue);
    };
    setAssetsWithDetailsLoading(true)
    fetchAssetsDetails();
  }, [asset_id, assetSummaryOverviewDateFilterValue]);
}

export default AssetDetails