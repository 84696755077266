import React, {useState, useEffect, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import lodash from 'lodash'
import {Typography} from "@mui/material";
import {Formik, Field, Form} from 'formik';
import MailIcon from '../../components/icons/MailIcon';
import {getNewAccessToken} from '../../redux/slices/auth/authSlice';

import {getUserProfileData, updateUserProfile} from '../../redux/slices/account/accountSettingSlice';
import {showAlertModal} from '../../redux/slices/alertSlice';
import {getOrganizationDataForEdit, updateOrganization} from "../../redux/slices/organization/organizationSlice";
import {getOrganizationIdOFLoggedUser} from "../../util/commonFunctions";

function OrganizationSettingForm() {

    const dispatch = useDispatch()
    const user = useSelector(state => state.auth.user)

    const organizationData = useSelector(state => state.organization.editOrganization)

    const [serverError, setServerError] = useState([])


    useGetOrganizationData(dispatch, user)

    const formReadyToSubmit = (values) => {
        if (!values.name) {
            return false;
        } else {
            return true;
        }
    }

    const updateOrganizationName = (values, retry = 0) => {

        values['main_contact'] = organizationData && organizationData.main_contact
        values['type'] = organizationData && organizationData.type
        dispatch(updateOrganization({values: values, id: organizationData && organizationData.id }))
            .then(({payload}) => {
                if (payload && payload.status === 200) {
                    let params = {
                        alertTitle: 'Organization info',
                        alertMessage: 'Your organization info has been updated.',
                        alertType: 'user_profile',
                    }
                    dispatch(showAlertModal(params))
                } else if (payload && payload.response && payload.response.status === 400) {
                    const errorArray = {};
                    for (const key in payload.response.data) {
                        if (Object.hasOwnProperty.call(payload.response.data, key)) {
                            const value = payload.response.data[key]
                            errorArray[key] = value.join('. ')
                        }
                    }
                    setServerError([errorArray])
                } else if (payload && payload.response && payload.response.status === 401 && retry < process.env.REACT_APP_MAX_RETRY_FOR_REFRESH_TOKEN) {
                    dispatch(getNewAccessToken())
                        .then((res) => {
                            if (res.payload && res.payload.data && res.payload.data.success) {
                                return updateOrganizationName(values, retry + 1)
                            }
                        })
                }
            })
    }

    return (
        <div className="account-setting-profile-form-section">
            <div className="account-setting-profile-form-container">
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        name: organizationData.name || ''
                    }}
                    onSubmit={(values) => {
                        if (formReadyToSubmit(values)) {
                            setServerError([])
                            updateOrganizationName(values)
                        } else {
                            let errorArray = {}
                            errorArray["Name"] = "Organization name cannot be empty. "
                            setServerError([errorArray])
                        }
                    }}
                >
                    {({errors, values, handleChange, handleSubmit, setFieldValue}) => (
                        <Form className="account-setting-profile-form">
                            <div className="account-setting-profile-form-header">
                                <div className="account-setting-profile-form-header-content">
                                    <div className="intro">
                                        <Typography className="title">Organization info</Typography>
                                        <Typography className="info">Update your organization details here.</Typography>
                                    </div>
                                    <div className="action-buttons">
                                        <button type="submit" className="org-save">Save</button>
                                        {/*<button type="submit" className={"disable-save-button " + `${ formReadyToSubmit(values) ? 'save' : '' }`}>Save</button>*/}
                                    </div>
                                </div>
                                <div className="divider"></div>
                            </div>
                            <div className="account-setting-profile-form-body">
                                <div className="email-input-content">
                                    <Typography className="label">Name</Typography>
                                    <div className="input-with-icon-view">
                                        <div className="input-content">
                                            {/*<MailIcon/>*/}
                                            <Field className="input-with-icon" placeholder="Organization name"
                                                   name="name"/>
                                        </div>
                                    </div>
                                </div>
                                {serverError && serverError.map((error, index) => (
                                    error[lodash.keys(error)[0]] &&
                                    <div className="error">{error[lodash.keys(error)[0]]}</div>
                                ))}
                                <div className="divider"></div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

async function getOrganization(dispatch, user, retry = 0) {

    const {payload} = await dispatch(getOrganizationDataForEdit(getOrganizationIdOFLoggedUser(user)))

    if (payload && payload.response && payload.response.status === 401 && retry < process.env.REACT_APP_MAX_RETRY_FOR_REFRESH_TOKEN) {
        await dispatch(getNewAccessToken());

        return getOrganization(dispatch, user,retry + 1);
    }

    return payload
}

function useGetOrganizationData(dispatch, user) {
    const isMountRef = useRef(true);
    useEffect(() => {
        if (isMountRef.current) {
            const fetchOrganization = async () => {
                const usersData = await getOrganization(dispatch, user);
            };

            fetchOrganization();
            isMountRef.current = false;
        }
    }, []);
}

export default OrganizationSettingForm