import React,{ useEffect, useState } from 'react'
import lodash from 'lodash'
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field, Form } from 'formik';
import {colors, Modal, Typography} from "@mui/material";
import AddUserModalHeaderUserIcon from '../../../icons/AddUserModalHeaderUserIcon';
import ModalCloseIcon from '../../../icons/ModalCloseIcon';
import SimpleSelectInput from '../../../commonComponents/SimpleSelectInput';
import MailIcon from '../../../icons/MailIcon';

import {createNewEntity, hideAddEntityModal} from '../../../../redux/slices/user/userSlice';
import { createNewUser } from '../../../../redux/slices/user/userSlice';
import { getNewAccessToken } from '../../../../redux/slices/auth/authSlice';
import AddEntityModalHeaderUserIcon from "../../../icons/AddEntityModalHeaderUserIcon";
import Loader from "../../../commonComponents/Loader";

function AddEntityModal() {

    const dispatch = useDispatch();

    const user = useSelector(state => state.auth.user)
    const addEntityModalShown = useSelector(state => state.user.addEntityModalShown)
    const user_organization_list = useSelector(state => state.user.user_organization_list)
    const user_role_list = useSelector(state => state.user.user_role_list)


    const [Loading, setLoading] = useState(false)
    const [serverError, setServerError] = useState([])

    useResetServerErrorOnOpen(addEntityModalShown, setServerError)

    const closeAddEntityModal = () => {
        dispatch(hideAddEntityModal())
    }
    function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const formReadyToSubmit = (values) => {
        if(!values.name) {
            return false;
        }
        else {
            return true;
        }
    }

    const submitAddNewUser = (values, retry=0) => {
        values['organization'] = user.organization
        setLoading(true)
        dispatch(createNewEntity(values))
        .then(({payload}) => {
            if(payload && payload.status === 201) {
                setLoading(false)
                closeAddEntityModal()
            }
            else if(payload && payload.response && payload.response.status === 400) {
                const errorArray = {};
                for( const key in payload.response.data ) {
                    if(Object.hasOwnProperty.call(payload.response.data, key)) {
                        const value = payload.response.data[key]
                        errorArray[key] = value.join('. ')
                    }
                }
                setLoading(false)
                setServerError([errorArray])
            }
            else if(payload && payload.response && payload.response.status === 401 && retry < process.env.REACT_APP_MAX_RETRY_FOR_REFRESH_TOKEN) {
                dispatch(getNewAccessToken())
                .then((res) => {
                    if(res.payload && res.payload.data && res.payload.data.success) {
                        return submitAddNewUser(values, retry + 1)
                    }
                })
            }
        })
    }

    return (
        <Modal
            open={addEntityModalShown}
            onClose={ closeAddEntityModal }
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="add-user-modal-view"
        >
            <div className="add-user-modal">
                <div className="add-user-modal-header">
                    <div className="add-user-modal-header-content">
                        <div className="add-user-modal-header-icons-view">
                            <div className="add-user-modal-header-feature-icon">
                                <AddEntityModalHeaderUserIcon />
                            </div>
                            <div className="add-user-modal-header-close-button">
                                <ModalCloseIcon closeModal={ closeAddEntityModal } />
                            </div>
                        </div>
                        <div className="add-user-modal-header-text">
                            <Typography className="add-user-modal-header-title-text">Add Entity</Typography>
                            <Typography className="add-user-modal-header-info-text">Enter entity details below.</Typography>
                        </div>
                    </div>
                </div>
                <div className="add-user-modal-content">
                    <Formik
                        initialValues={{
                            name: '',
                            email: null
                        }}
                        onSubmit={(values) => { 
                            if(formReadyToSubmit(values)) {
                                setServerError([])
                                submitAddNewUser(values)
                            }
                        }}
                    >
                        {({ errors, values, handleChange, handleSubmit, setFieldValue }) => (
                            <Form className="add-user-form">
                                <div className="input-field-view">
                                    <div className="input-with-label">
                                        <Typography className="input-label">Entity Name*</Typography>
                                        <div className="row-input-with-icon-view">
                                            <div className="input-content">
                                                <Field className="input-with-icon" placeholder="What is the entity’s name?" name="name" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="input-field-view">
                                    <div className="input-with-label">
                                        <Typography className="input-label">Main Contact Email</Typography>
                                        <div className="row-input-with-icon-view">
                                            <div className="input-content">
                                                <Field className="input-with-icon" placeholder="Input contact person email" type="email" name="email" />
                                            </div>
                                        </div>
                                    </div>
                                    <Typography style={{color: '#475467'}} className="input-label">This person will receive an invitation to register as the main contact for this entity.
                                    </Typography>
                                </div>
                                <div className="add-user-modal-footer">
                                    <div className="add-user-modal-footer-content">
                                        {
                                            Loading ? <Loader width="5%" height="5%"/> : null
                                        }
                                        { serverError && serverError.map((error, index) => (
                                            error[lodash.keys(error)[0]] && <div className="error">{ capitalizeFirstLetter(error[lodash.keys(error)[0]]) }</div>
                                        ))}
                                        <div className="add-user-modal-footer-buttons">
                                            <button type="button" onClick={ closeAddEntityModal } className="cancel-user-button">Cancel</button>
                                            <button type="submit" className={"disable-add-user-button " + `${ formReadyToSubmit(values) ? 'enable-add-user-button' : '' }`}>Add</button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </Modal>
    )
}

function useResetServerErrorOnOpen(addEntityModalShown, setServerError) {
    useEffect(() => {
        if(addEntityModalShown) {
            setServerError([])
        }
    }, [addEntityModalShown])
}

export default AddEntityModal