import React from 'react'
import { Typography } from "@mui/material";

function AccountSettingTitle(props) {
  return (
    <div className="account-setting-title-section">
        <div className="account-setting-container">
            <div className="page-header">
                <div className="content">
                    <Typography>{props.name}</Typography>
                </div>
            </div>

        </div>
    </div>
  )
}

export default AccountSettingTitle