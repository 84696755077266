import React from 'react'

export default function ResendIcon(props) {
    return (
        // <svg onClick={!props.disable ? props.remove : null} className={props.marginRight ? 'trash-icon me-4' : ''}
        //      width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        //     <path
        //         d="M12.3333 5.00033V4.33366C12.3333 3.40024 12.3333 2.93353 12.1517 2.57701C11.9919 2.2634 11.7369 2.00844 11.4233 1.84865C11.0668 1.66699 10.6001 1.66699 9.66667 1.66699H8.33333C7.39991 1.66699 6.9332 1.66699 6.57668 1.84865C6.26308 2.00844 6.00811 2.2634 5.84832 2.57701C5.66667 2.93353 5.66667 3.40024 5.66667 4.33366V5.00033M7.33333 9.58366V13.7503M10.6667 9.58366V13.7503M1.5 5.00033H16.5M14.8333 5.00033V14.3337C14.8333 15.7338 14.8333 16.4339 14.5608 16.9686C14.3212 17.439 13.9387 17.8215 13.4683 18.0612C12.9335 18.3337 12.2335 18.3337 10.8333 18.3337H7.16667C5.76654 18.3337 5.06647 18.3337 4.53169 18.0612C4.06129 17.8215 3.67883 17.439 3.43915 16.9686C3.16667 16.4339 3.16667 15.7338 3.16667 14.3337V5.00033"
        //         stroke="#475467" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
        // </svg>

        <svg onClick={!props.disable ? props.resend : null} className={props.marginRight ? 'trash-icon me-4' : ''}
             width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.74952 11.2501L17.4995 2.50014M8.85584 11.5235L11.0459 17.1552C11.2389 17.6513 11.3353 17.8994 11.4743 17.9718C11.5948 18.0346 11.7384 18.0347 11.859 17.972C11.998 17.8998 12.0948 17.6518 12.2883 17.1559L17.7803 3.08281C17.955 2.63516 18.0423 2.41133 17.9945 2.26831C17.953 2.1441 17.8556 2.04663 17.7314 2.00514C17.5883 1.95736 17.3645 2.0447 16.9169 2.21939L2.84373 7.71134C2.34784 7.90486 2.09989 8.00163 2.02763 8.14071C1.96499 8.26129 1.96508 8.40483 2.02786 8.52533C2.10028 8.66433 2.34834 8.7608 2.84446 8.95373L8.47613 11.1438C8.57684 11.183 8.62719 11.2026 8.66959 11.2328C8.70717 11.2596 8.74004 11.2925 8.76685 11.3301C8.79709 11.3725 8.81667 11.4228 8.85584 11.5235Z"
                stroke="#475467" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}