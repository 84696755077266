import React, {useEffect, useRef} from 'react';
// import { useDispatch, useSelector } from 'react-redux';
import EntityHeaderContent from './EntityHeaderContent';
import EntityTable from './EntityTable';
import {useDispatch, useSelector} from "react-redux";
import {getOrganizationEntities, getUserList} from "../../../redux/slices/user/userSlice";
import {getNewAccessToken} from "../../../redux/slices/auth/authSlice";

function Entity(props) {

    const dispatch = useDispatch()

    const user = useSelector(state => state.auth.user)
    const entities = useSelector(state => state.user.entities)
    const entities_table_columns = useSelector(state => state.user.entities_organization_list)
    // This is for users name search
    const user_search_filter_value = useSelector(state => state.user.userSearchFilterValue)

    useGetUsersList(dispatch, user, user_search_filter_value)

    return (
        <>
            <div className='header-section'>
                <div className='user-page-container'>
                    <div className='user-page-header'>
                        {/*<UserBreadCrumbs />*/}
                        <EntityHeaderContent/>
                    </div>
                </div>
            </div>
            {entities && entities_table_columns && <EntityTable tableData={entities} tableHeader={entities_table_columns}/>}
        </>
    )
}

async function getEntities(dispatch, user, user_search_filter_value, retry = 0) {
    let values = {
        'organization_id': user.organization,
        userSearchFilterValue: user_search_filter_value,
    }
    const {payload} = await dispatch(getOrganizationEntities(values))

    if (payload && payload.response && payload.response.status === 401 && retry < process.env.REACT_APP_MAX_RETRY_FOR_REFRESH_TOKEN) {
        await dispatch(getNewAccessToken());

        // Retry the "get organization data" call with the new token
        return getEntities(dispatch, user, user_search_filter_value, retry + 1);
    }

    return payload
}

function useGetUsersList(dispatch, user, user_search_filter_value) {
    const isMountRef = useRef(true);
    useEffect(() => {
        if (isMountRef.current) {

            const fetchEntities = async () => {
                const usersData = await getEntities(dispatch, user, user_search_filter_value);
            };

            fetchEntities();
            // isMountRef.current = false;
        }
    }, [user_search_filter_value]);
}

export default Entity;