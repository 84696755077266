import React, {useState, useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Select, {components} from 'react-select';
import {createPortal} from 'react-dom';
import {getOrganizationIdOFLoggedUser, isAddAssetAllowedToLoggedUser} from '../../../../util/commonFunctions';

import {getNewAccessToken} from '../../../../redux/slices/auth/authSlice';
import {updateAsset} from '../../../../redux/slices/assets/assetSummarySlice';
import TableActionButtons from "../../../../components/table/TableActionButtons";
import lodash from "lodash";
import {showConfirmationAlertModal} from "../../../../redux/slices/alertSlice";

export default function AssetSummaryTable(props) {

    const dispatch = useDispatch()
    const [orderBy, setOrderBy] = useState('name'); // Column to sort by
    const [orderDirection, setOrderDirection] = useState('asc'); // Sorting direction (asc/desc)
    const [sortedData, setSortedData] = useState(props.tableData); // Data to display in the table

    useEffect(() => {
        setSortedData(props.tableData);
    }, [props.tableData]);


    const handleSort = (columnKey) => {
        const isAsc = orderBy === columnKey && orderDirection === 'asc';
        const direction = isAsc ? 'desc' : 'asc';
        setOrderDirection(direction);
        setOrderBy(columnKey);

        // Sorting logic
        const sortedRes = [...sortedData].sort((a, b) => {
            if (a[columnKey] < b[columnKey]) {
                return direction === 'asc' ? -1 : 1;
            }
            if (a[columnKey] > b[columnKey]) {
                return direction === 'asc' ? 1 : -1;
            }
            return 0;
        });
        setSortedData(sortedRes);
    };

    const user = useSelector(state => state.auth.user)

    const getHeaderWidth = (header) => {
        if (user.role !== 4) {
            switch (header.col_key) {
                case 'name':
                    return '11.9375rem';
                case 'asset_class':
                    return '8.8125rem';
                case 'sub_asset_class':
                    return '8.375rem';
                case 'commitment':
                    return '2.5625rem';
                case 'invested':
                    return '6.125rem';
                case 'current_value':
                    return '7.4375rem';
                case 'lifetime_mom':
                    return '7.5625rem';
                case 'lifetime_irr':
                    return '6.875rem';
                case 'status':
                    return '7.8125rem';
                default:
                    return '0rem';
            }
        }

        switch (header.col_key) {
            case 'name':
                return '13.9375rem';
            case 'asset_class':
                return '12.8125rem';
            case 'sub_asset_class':
                return '15.375rem';
            case 'invested':
                return '8.125rem';
            case 'current_value':
                return '9.4375rem';
            case 'lifetime_mom':
                return '9.5625rem';
            case 'lifetime_irr':
                return '9.875rem';
            default:
                return '0rem';
        }

    };

    const statusName = (status_code) => {
        switch (status_code) {
            case -1:
                return 'Negative';
            case 0:
                return 'Neutral';
            case 1:
                return 'Positive';
            default:
                return '';
        }
    };

    const statusClassName = useCallback((status_code) => {
        switch (status_code) {
            case -1:
                return 'negative';
            case 0:
                return 'neutral';
            case 1:
                return 'positive';
            default:
                return '';
        }
    }, []);


    const statusBadge = useCallback((status_code) => {
        return (
            <div className={"status-badge " + statusClassName(status_code)}>
                {
                    statusName(status_code)
                }
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                    <path d="M3 4.5L6 7.5L9 4.5" stroke="#344054" strokeWidth="1.5" strokeLinecap="round"
                          strokeLinejoin="round"/>
                </svg>
            </div>
        )
    }, [])

    const getConvertedValue = useCallback((number, full_value=false) => {
        // return '$' + (number / 1000000).toFixed(1) + "M"
        let round_num = 1
        if (full_value === true) {
            round_num = 0
        }
        if (number >= 1000000000) {
            return '$' + (number / 1000000000).toFixed(round_num) + "B"
        }
        if (number >= 1000000) {
            return '$' + (number / 1000000).toFixed(round_num) + "M"
        } else if (number < 1000000) {
            return '$' + (number / 1000).toFixed(round_num) + "K"
        }
    }, [])

    const getMomValue = useCallback((mom) => {
        if (mom) {
            return mom.toFixed(1) + 'x'
        } else {
            return ''
        }

    }, [])

    const getCommitmentSum = () => {
        let commitmentSum = 0;
        sortedData.map((row, index) => {
            commitmentSum = +commitmentSum + row.commitment
        })

        return getConvertedValue(commitmentSum, true);
    }

    const getInvestedSum = () => {
        let investedSum = 0;
        sortedData.map((row, index) => {
            investedSum = +investedSum + row.invested
        })

        return getConvertedValue(investedSum, false);
    }

    const getCurrentValueSum = () => {
        let currentValueSum = 0;
        sortedData.map((row, index) => {
            currentValueSum = +currentValueSum + row.current_value
        })

        return getConvertedValue(currentValueSum, false);
    }

    const getLifetimeMoMSum = () => {
        let lifetimeMoMSum = 0;
        lifetimeMoMSum = props.summaryData.lifetime_mom
        return lifetimeMoMSum.toFixed(1) + 'x';
    }

    const getLifetimeIRRSum = () => {
        let lifetimeIRR = 0;
        lifetimeIRR = props.summaryData.lifetime_irr

        return lifetimeIRR.toFixed(1) + '%';
    }

    const options = [
        {value: -1, label: 'Negative'},
        {value: 0, label: 'Neutral'},
        {value: 1, label: 'Positive'}

    ];

    function StatusDropDown(props) {
        const customStyles = {
            indicatorSeparator: () => ({display: 'none'})
        };


        function DownChewron() {
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                    <path d="M3 4.5L6 7.5L9 4.5" stroke="#344054" strokeWidth="1.5" strokeLinecap="round"
                          strokeLinejoin="round"/>
                </svg>
            )
        }

        const Control = ({children, ...props}) => {
            return (
                <components.Control {...props}>
                    {children}<DownChewron/>
                </components.Control>
            )
        }

        const [selectedValue, setSelectedValue] = useState(options.find(option => option.value === props.status_code));

        const updateAssetStatus = (status_code, retry = 0) => {
            let params = {
                status: {status: status_code},
                organization: getOrganizationIdOFLoggedUser(user),
                id: props.id,
            }

            dispatch(updateAsset(params))
                .then(({payload}) => {
                    if (payload && payload.status === 200) {
                    } else if (payload && payload.response && payload.response.status === 401 && retry < process.env.REACT_APP_MAX_RETRY_FOR_REFRESH_TOKEN) {
                        dispatch(getNewAccessToken())
                            .then((res) => {
                                if (res.payload && res.payload.data && res.payload.data.success) {
                                    return updateAssetStatus(status_code, retry + 1)
                                }
                            })
                    }
                })
        }

        const handleSelectChange = selectedOption => {
            setSelectedValue(selectedOption);
            updateAssetStatus(selectedOption.value)
        };

        return (
            <Select
                styles={customStyles}
                isSearchable={false}
                className={"basic-single status-selector " + statusClassName(selectedValue.value) + `${props.isLast ? ' last-status-selector' : ''}`}
                classNamePrefix="select"
                name="color"
                options={options}
                onChange={handleSelectChange}
                value={selectedValue}
                menuPlacement={"auto"}
                components={{
                    Control: Control,
                }}
            />
        )
    }

    const assetSummaryDetail = useCallback((asset_id, investment) => {
        props.setSelectedAssetIdToViewAssetDetail(asset_id)
        props.setSelectedAssetInvestmentTypeDetail(investment)
    }, [])

    const getSortIndicator = (columnKey) => {
        if (orderBy === columnKey) {
            return orderDirection === 'asc' ? ' ▲' : ' ▼';
        }
        return '';
        // return '↕';
    };

    const removeUser = useCallback((id) => {
        let asset = lodash.find(sortedData, function (asset) {
            return asset.id === id
        })
        let alert_msg = 'Are you sure you want to delete asset: ' + asset.name + '?'
        // if (event.is_creation_event) {
        //     alert_msg =  'Are you sure you want to delete this asset ? Deleting this asset will delete the associated events'
        // }
        let alert_params = {
            alertTitle: 'Delete Asset',
            alertMessage: alert_msg,
            alertType: 'remove_asset',
            screen: 'asset_summary',
            removing_id: id,
            organization: getOrganizationIdOFLoggedUser(user),
            asset_id: id,
        }

        dispatch(showConfirmationAlertModal(alert_params))
    }, [sortedData, user])

    return (
        <TableContainer className="table-container" component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow style={{backgroundColor: '#FFF', zIndex: 10, position: 'sticky', top: 0}}>
                        {props.tableHeader.map((header, index) => (

                            (user.role === 4 && (header.col_name === 'Commitment' || header.col_name === 'Status')) ? null :
                            (
                                <TableCell
                                    key={index}
                                    style={{
                                        width: getHeaderWidth(header),
                                        cursor: 'pointer',
                                        position: 'sticky',
                                        top: 0,
                                        zIndex: 10,
                                        backgroundColor: '#FFF',
                                        // borderBottom: '2px solid #E0E0E0', // Add border bottom for the header
                                        boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.12)', // Add shadow effect
                                    }}
                                    align="left"
                                    onClick={() => handleSort(header.col_key)}
                                >
                                    {header.col_name}
                                    {getSortIndicator(header.col_key)}
                                </TableCell>
                            )
                        ))}
                        {user.role !== 4 && <TableCell style={{
                            width: '6.125rem',
                            cursor: 'pointer',
                            position: 'sticky',
                            top: 0,
                            zIndex: 10,
                            backgroundColor: '#FFF',
                            // borderBottom: '2px solid #E0E0E0', // Add border bottom for the header
                            boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.12)', // Add shadow effect
                        }} align="left">Notes</TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedData.map((row, index) => (
                        <TableRow
                            className='table-data-row'
                            key={index}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        >
                            <TableCell onClick={() => assetSummaryDetail(row.id, row.investment)} align="left"
                                       scope="row">{row.name}</TableCell>
                            <TableCell onClick={() => assetSummaryDetail(row.id, row.investment)}
                                       align="left">{row.asset_class}</TableCell>
                            <TableCell onClick={() => assetSummaryDetail(row.id, row.investment)}
                                       align="left">{row.sub_asset_class}</TableCell>
                            {user.role !== 4 && <TableCell onClick={() => assetSummaryDetail(row.id, row.investment)}
                                       align="left">{getConvertedValue(row.commitment, true)}</TableCell>}
                            <TableCell onClick={() => assetSummaryDetail(row.id, row.investment)}
                                       align="left">{getConvertedValue(row.invested, false)}</TableCell>
                            <TableCell onClick={() => assetSummaryDetail(row.id, row.investment)}
                                       align="left">{getConvertedValue(row.current_value, false)}</TableCell>
                            <TableCell onClick={() => assetSummaryDetail(row.id, row.investment)}
                                       align="left">{getMomValue(row.lifetime_mom)}</TableCell>
                            <TableCell onClick={() => assetSummaryDetail(row.id, row.investment)}
                                       align="left">{row.lifetime_irr.toFixed(1)}%</TableCell>
                            {user.role !== 4 && <TableCell className="status" align="left"><StatusDropDown
                                isLast={sortedData.length - 1 == index} status_code={row.status} id={row.id}
                                length={sortedData.length - 1} index={index}/></TableCell>}

                            {user.role !== 4 && <TableCell align="left">{<TableActionButtons toolTipData="" disable={false}
                                                                         isFileAllow={false} viewable={true}
                                                                         showToolTip={true}
                                                                         isRemoveAllow={isAddAssetAllowedToLoggedUser(user)}
                                                                         isEditAllow={false} isExportAllow={false}
                                                                         remove={() => removeUser(row.id)}/>}</TableCell>}
                        </TableRow>
                    ))}
                    <TableRow className="total-row"
                              sx={{'&:last-child td, &:last-child th': {border: 0}}}
                    >
                        <TableCell align="left" scope="row">Total</TableCell>
                        <TableCell align="left"></TableCell>
                        <TableCell align="left"></TableCell>
                        {user.role !== 4 && <TableCell align="left">{getCommitmentSum()}</TableCell>}
                        <TableCell align="left">{getInvestedSum()}</TableCell>
                        <TableCell align="left">{getCurrentValueSum()}</TableCell>
                        <TableCell align="left">{getLifetimeMoMSum()}</TableCell>
                        <TableCell align="left">{getLifetimeIRRSum()}</TableCell>
                        {user.role !== 4 && <TableCell align="left"></TableCell>}
                        {user.role !== 4 && <TableCell align="left"></TableCell>}
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}