import React, {useCallback, useState} from 'react';
import { useDispatch } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableActionButtons from '../../components/table/TableActionButtons';
import UserIcon from '../../components/icons/UserIcon';

import {
    getUserDataForEdit,
    showEditUserModal,
    getUserOrganizationANdRoleList,
    resendInvite, getUserOrganizationANdRoleListForManager
} from '../../redux/slices/user/userSlice';
import { getNewAccessToken } from '../../redux/slices/auth/authSlice';
import {showAlertModal, showConfirmationAlertModal} from '../../redux/slices/alertSlice';
import {updateOrganization} from "../../redux/slices/organization/organizationSlice";

export default function UserTable (props) {

  const dispatch = useDispatch()
  const [serverError, setServerError] = useState([])

  const getHeaderWidth = (header)=> {
    if(header.col_key === 'username') {
      return '14.4375rem'
    }
    else if(header.col_key === 'email') {
      return '15.1875rem'
    }
    else if(header.col_key === 'entity_name') {
      return '10.875rem'
    }
    else if(header.col_key === 'role_name') {
      return '11.5rem'
    }
    else if(header.col_key === 'is_active') {
      return '8.3125rem'
    }
  }

  const showEditModal = useCallback((id, retry=0) => {
    dispatch(getUserDataForEdit(id))
    .then(({payload}) => {
      if(payload && payload.status === 200) {
        dispatch(getUserOrganizationANdRoleListForManager({values: {is_manager: true}}))
        dispatch(showEditUserModal())
      }
      else if(payload && payload.response && payload.response.status === 401 && retry < process.env.REACT_APP_MAX_RETRY_FOR_REFRESH_TOKEN) {
        dispatch(getNewAccessToken())
        .then((res) => {
          if(res.payload && res.payload.data && res.payload.data.success) {
            return showEditModal(id, retry + 1)
          }
        })
      }
    })
  }, [])

  const removeUser = useCallback((id) => {
    let alert_params = {
      alertTitle: 'Remove User',
      alertMessage: 'Are you sure you want to delete the user?',
      alertType: 'remove_user',
      removing_id: id,
    }

    dispatch(showConfirmationAlertModal(alert_params))
  }, [])

  const resend_invite = useCallback((id) => {

    let params = {
        alertTitle: 'Re-send Invitation',
        alertMessage: 'Invitation sent to the user.',
        alertType: 'user_profile',
    }
    dispatch(showAlertModal(params))


    dispatch(resendInvite({values: {}, id: id}))
            .then(({payload}) => {
                if (payload && payload.status === 200) {
                    let params = {
                        alertTitle: 'Re-send Invitation',
                        alertMessage: 'Invitation sent to the user.',
                        alertType: 'user_profile',
                    }
                    dispatch(showAlertModal(params))
                } else if (payload && payload.response && payload.response.status === 400) {
                    const errorArray = {};
                    for (const key in payload.response.data) {
                        if (Object.hasOwnProperty.call(payload.response.data, key)) {
                            const value = payload.response.data[key]
                            errorArray[key] = value.join('. ')
                        }
                    }
                    setServerError([errorArray])
                }
            })
  }, [])


  return (
    <TableContainer className="table-container" component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
          { props.tableHeader.map((header, index) => (
              <TableCell key={index} style={{ width: getHeaderWidth(header) }} align="left">{ header.col_name }</TableCell>
          ))}
          <TableCell style={{ width: '8.1875rem' }} align="left">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          { props.tableData.map((row, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left" scope="row"><UserIcon />{[row.firstname, row.lastname].join(' ')}</TableCell>
              <TableCell align="left">{row.email}</TableCell>
              <TableCell align="left">{row.entity_name}</TableCell>
              <TableCell align="left">{row.role_name}</TableCell>
              { row.is_active ? <TableCell className="active-status" align="left"><span>Active</span></TableCell> : <TableCell className="pending-status" align="left"><span>Pending</span></TableCell> }
              <TableCell align="left">{<TableActionButtons disable={ false } isRemoveAllow={ true } isEditAllow={ true } isReSendAllow={ !row.is_active } remove={ () => removeUser(row.id)} resend={ () => resend_invite(row.id)} showEditModal={ () => showEditModal(row.id) } />}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}