import React, {useEffect, useRef} from 'react';
// import { useDispatch, useSelector } from 'react-redux';
import UserHeaderContent from './UserHeaderContent';
import UserTable from './UserTable';
import {getUserList} from "../../redux/slices/user/userSlice";
import {useDispatch, useSelector} from "react-redux";
import {getNewAccessToken} from "../../redux/slices/auth/authSlice";
import UserBreadCrumbs from "./UserBreadCrumbs";

function User(props) {

    const dispatch = useDispatch()

    const user = useSelector(state => state.auth.user)
    const users = useSelector(state => state.user.users)
    const users_table_columns = useSelector(state => state.user.users_table_columns)
    // This is for users name search
    const user_search_filter_value = useSelector(state => state.user.userSearchFilterValue)

    useGetUsersList(dispatch, user, user_search_filter_value)

    return (
        <>
            <div className='header-section'>
                <div className='user-page-container'>
                    <div className='user-page-header'>
                        {/*<UserBreadCrumbs />*/}
                        <UserHeaderContent/>
                    </div>
                </div>
            </div>
            {users && users_table_columns && <UserTable tableData={users} tableHeader={users_table_columns}/>}
        </>
    )
}

async function getUsers(dispatch, user, user_search_filter_value, retry = 0) {
    let data = {
        "specific_org": true,
        "user_id": user.id,
        userSearchFilterValue: user_search_filter_value,
    }
    const {payload} = await dispatch(getUserList(data))

    if (payload && payload.response && payload.response.status === 401 && retry < process.env.REACT_APP_MAX_RETRY_FOR_REFRESH_TOKEN) {
        await dispatch(getNewAccessToken());

        // Retry the "get organization data" call with the new token
        return getUsers(dispatch, user, user_search_filter_value, retry + 1);
    }

    return payload
}

function useGetUsersList(dispatch, user, user_search_filter_value) {
    const isMountRef = useRef(true);
    useEffect(() => {
        if (isMountRef.current) {

            const fetchUsers = async () => {
                const usersData = await getUsers(dispatch, user, user_search_filter_value);
            };

            fetchUsers();
            // isMountRef.current = false;
        }
    }, [user_search_filter_value]);
}

export default User;