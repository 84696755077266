import adminRoutes from "../routes/admin/adminRoutes"
import initialAdminRoutes from "../routes/admin/initialAdminRoutes"
import userRoutes from "../routes/user/userRoutes"
import managerRoutes from "../routes/manager/managerRoutes"
import entityRepRoutes from "../routes/user/entityRepRoutes";

/**
 * Give logged in user access token
 *
 * @returns { String }
 */
export function getLoggedUserAccessToken() {
    if(localStorage.getItem('access_token')) {
       const access_token = localStorage.getItem('access_token')
       return JSON.parse(access_token)
    }
}

/**
 * Give logged in user refresh token
 *
 * @returns { String }
 */
export function getLoggedUserRefreshToken() {
    if(localStorage.getItem('refresh_token')) {
       const refresh_token = localStorage.getItem('refresh_token')
       return JSON.parse(refresh_token)
    }
}

/**
 * Give the logged in user routes
 *
 * @param { Object } user
 * @returns { Array } routeList
 */
export function getLoggedUserRouteList(user) {
    if(localStorage.getItem('user')) {
        if(user && user.hasOwnProperty('id') && user.hasOwnProperty('role') && user.role === 1) {
            if(localStorage.getItem('organization_id')) {
                return adminRoutes;
            }
            else {
                return initialAdminRoutes;
            }
        }
        else if(user && user.hasOwnProperty('id') && user.hasOwnProperty('role') && (user.role === 2 || user.role === 4)) {
            if (user.role === 4){
                return entityRepRoutes
            }
            return userRoutes;
        }
        else if(user && user.hasOwnProperty('id') && user.hasOwnProperty('role') && user.role === 3) {
            return managerRoutes;
        }
    }
}

/**
 * Give the initial Route based on role
 *
 * @returns { String } name
 */
export function getLoggedUserInitialRoute(user) {
    if(localStorage.getItem('user')) {
        if(user && user.hasOwnProperty('id') && user.hasOwnProperty('role') && user.role === 1) {
            return '/organization';
        }
        else if(user && user.hasOwnProperty('id') && user.hasOwnProperty('role') && (user.role === 2 || user.role === 4)) {
            return '/portfolio-overview';
        }
        else if(user && user.hasOwnProperty('id') && user.hasOwnProperty('role') && user.role === 3) {
            return '/portfolio-overview';
        }
    }
}

/**
 * Give the initial Route based on role
 *
 * @returns { String } name
 */
export function isRoleAllowedToAccessGiverRoute(allowedRoles, user) {

    const rolesList = {
        1: 'admin',
        2: 'user',
        3: 'manager',
        4: 'entity rep'
    };

    if(allowedRoles.includes(rolesList[user.role])) {
        return true;
    }

    return false;
}

/**
 * Give the initial Route based on role
 *
 * @returns { String } name
 */
export function isSameRole(object1, object2) {

    if(object1.role !== object2.role) {
        return false;
    }

    return true;
}

/**
 * Add Asset Allowed to logged user
 *
 * @returns { Boolean }
 */
export function isAddAssetAllowedToLoggedUser(user) {

    if(user && (user.role === 1 || user.role === 3)) {
        return true;
    }

    return false;
}

/**
 * Get Organization ID Of logged user
 *
 * @returns { organization_id }
 */
export function getOrganizationIdOFLoggedUser(user) {
    if(user.role === 1 && localStorage.getItem('organization_id')) {
        return localStorage.getItem('organization_id');
    }
    else if(user.role === 2 || user.role === 3 || user.role === 4) {
        return user.organization;
    }
}

/**
 * Get Organization ID Of logged user
 *
 * @returns { organization_id }
 */
export function isAdminAllowedForSpecificRoutes() {
    if(localStorage.getItem('organization_id')) {
        return 'admin';
    }
}