import React, { useState, useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ReactApexChart from 'react-apexcharts';
import lodash from 'lodash';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AssetSummaryLineChart from '../assets/AssetSummaryLineChart';
import Loader from '../../../../components/commonComponents/Loader';

import { getOrganizationIdOFLoggedUser } from '../../../../util/commonFunctions';
import { getNewAccessToken } from '../../../../redux/slices/auth/authSlice';
import { getYTDIRR } from '../../../../redux/slices/portfolio/portfolioSlice';
import { setInvestmentAssetsValueByCategoryViewGraphType, getInvestedAssetsValueByCategory } from '../../../../redux/slices/portfolio/portfolioSlice';
import { cloneDeep } from 'lodash';

function InvestmentValueByCategoryGraph() {
  const dispatch = useDispatch();

  const user = useSelector(state => state.auth.user)
  const investmentAssetsValueByCategoryViewGraph = useSelector(state => state.portfolio.investmentAssetsValueByCategoryViewGraph)
  const dates = useSelector(state => state.portfolio.dates)
  const categories = useSelector(state => state.portfolio.categories)
  const entities = useSelector(state => state.portfolio.entities)
  const filterDate = useSelector(state => state.portfolio.filterDate)
  const filterCategory = useSelector(state => state.portfolio.filterCategory)
  const filterEntity = useSelector(state => state.portfolio.filterEntity)
  const all_time_irr = useSelector(state => state.portfolio.all_time_irr)
  const series = useSelector(state => state.portfolio.investmentAssetsValueByCategoryViewGraphSeries)
  const graphCategories = useSelector(state => state.portfolio.investmentAssetsValueByCategoryViewGraphCategories)

  const [investmentValueByCategoryGraphLoading, setInvestmentValueByCategoryGraphLoading] = useState(true)

  useGetInvestedAssetsEvalutationByCateogry(dispatch, user, filterDate, filterCategory, filterEntity, dates, categories, entities, all_time_irr, series, graphCategories, lodash, setInvestmentValueByCategoryGraphLoading);

  const graphConfig = useMemo(() => {
    let config = {};
    if(investmentAssetsValueByCategoryViewGraph && investmentAssetsValueByCategoryViewGraph.hasOwnProperty('graphType') && investmentAssetsValueByCategoryViewGraph.hasOwnProperty('menuText') && series && graphCategories) {
      if(investmentAssetsValueByCategoryViewGraph.graphType === 'bar') {
        config = cloneDeep({
          series: series,
          options: {
            chart: {
              type: 'bar',
              height: 350,
              stacked: true,
              toolbar: {
                show: false,
              },
              zoom: {
                enabled: true
              }
            },
            responsive: [{
              breakpoint: 480,
              options: {
                legend: {
                  position: 'bottom',
                  offsetX: -10,
                  offsetY: 0
                }
              }
            }],
            plotOptions: {
              bar: {
                horizontal: false,
                borderRadius: 10,
                columnWidth: '25%',
                dataLabels: {
                  total: {
                    enabled: true,
                    formatter: function (val) {
                      // Format the label text with commas for thousands and 2 decimal places
                      return val.toLocaleString('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 1,
                        maximumFractionDigits: 1,
                      }) + 'M';
                    },
                    offsetY: -15,
                    style: {
                      fontSize: '13px',
                      fontWeight: 900,
                    },
                  },
                },
              },
            },
            yaxis: {
              title: {
                text: 'Amount',
                offsetX: 5.5,
              },
              labels: {
                formatter: function (value, index) {
                  if (value === 0) {
                    return '0';
                  } else {
                    return value.toLocaleString('en-US', {
                      style: 'decimal',
                      minimumFractionDigits: 1,
                      maximumFractionDigits: 1,
                    }) + 'M';
                  }
                },
              },
              tickAmount: 6,
              min: 0,
            },
            xaxis: {
              title: {
                text: 'Quarter'
              },
              categories: graphCategories,
            },
            legend: {
              position: 'top',
              horizontalAlign: 'right',
            },
            fill: {
              opacity: 1
            },
            dataLabels: {
              enabled: false,
            },
            tooltip: {
              enabled: true, // Enable tooltips
              y: {
                formatter: function (val) {
                  // Customize the tooltip content as needed
                  return val.toLocaleString('en-US', {
                      style: 'decimal',
                      minimumFractionDigits: 1,
                      maximumFractionDigits: 1,
                    }) + 'M';
                }
              }
            }      
          }
        });
      }
      else if(investmentAssetsValueByCategoryViewGraph.graphType === 'area') {
        config = cloneDeep({
          series: series,
          options: {
            chart: {
              type: 'area',
              stacked: false,
              height: 350,
              zoom: {
                type: 'x',
                enabled: true,
                autoScaleYaxis: true
              },
              toolbar: {
                show: false,
              }
            },
            dataLabels: {
              enabled: false
            },
            markers: {
              size: 0,
            },
            fill: {
              type: 'gradient',
              gradient: {
                shadeIntensity: 1,
                inverseColors: false,
                opacityFrom: 0.5,
                opacityTo: 0,
                stops: [0, 90, 100]
              },
            },
            yaxis: {
              title: {
                text: 'Amount',
                offsetX: 0,
              },
              labels: {
                formatter: function (value, index) {
                  if (value === 0) {
                    return '0';
                  } else {
                    return value.toFixed(1) + 'M';
                  }
                },
              },
              tickAmount: 6,
              min: 0,
            },
            xaxis: {
              title: {
                text: 'Quarter'
              },
              categories: graphCategories,
            },
            tooltip: {
              shared: false,
              y: {
                formatter: function (val) {
                  return (val).toFixed(1) + 'M'
                }
              }
            },
            legend: {
              position: 'top',
              horizontalAlign: 'right',
            }
          }
        });
      }
    }

    return config;

  }, [investmentAssetsValueByCategoryViewGraph, series, graphCategories]);
  
  const DropDownIcon = () => {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    }
    const handleClose = () => {
      setAnchorEl(null);
    }

    const handleSelect = () => {
      setAnchorEl(null);
      setInvestmentValueByCategoryGraphLoading(true)
      if(investmentAssetsValueByCategoryViewGraph.graphType === 'area') {
        dispatch(setInvestmentAssetsValueByCategoryViewGraphType({ graphType: 'bar', menuText: 'View line chart' }))
      }
      else if(investmentAssetsValueByCategoryViewGraph.graphType === 'bar') {
        dispatch(setInvestmentAssetsValueByCategoryViewGraphType({ graphType: 'area', menuText: 'View bar chart' }))
      }

      setInvestmentValueByCategoryGraphLoading(false)
    }

    const fetchViewGainLossData = (retry=0) => {
        const { payload } = dispatch(getYTDIRR({ organization: getOrganizationIdOFLoggedUser(user), gain: true }))
      
        if(payload && payload.response && payload.response.status === 401 && retry < process.env.REACT_APP_MAX_RETRY_FOR_REFRESH_TOKEN) {
          dispatch(getNewAccessToken());
          fetchViewGainLossData(retry + 1);
        }
    }
    
    return (
        <>
            <svg
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              className='three-dots' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M10 10.8334C10.4602 10.8334 10.8333 10.4603 10.8333 10C10.8333 9.5398 10.4602 9.16671 10 9.16671C9.53977 9.16671 9.16667 9.5398 9.16667 10C9.16667 10.4603 9.53977 10.8334 10 10.8334Z" stroke="#98A2B3" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M10 5.00004C10.4602 5.00004 10.8333 4.62694 10.8333 4.16671C10.8333 3.70647 10.4602 3.33337 10 3.33337C9.53977 3.33337 9.16667 3.70647 9.16667 4.16671C9.16667 4.62694 9.53977 5.00004 10 5.00004Z" stroke="#98A2B3" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M10 16.6667C10.4602 16.6667 10.8333 16.2936 10.8333 15.8334C10.8333 15.3731 10.4602 15 10 15C9.53977 15 9.16667 15.3731 9.16667 15.8334C9.16667 16.2936 9.53977 16.6667 10 16.6667Z" stroke="#98A2B3" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            <Menu
              id="basic-menu"
              className="view-bar-line-chart-investment-value-by-category-graph"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
              'aria-labelledby': 'basic-button',
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem onClick={ () => handleSelect() }>{ investmentAssetsValueByCategoryViewGraph.menuText }</MenuItem>
            </Menu>
        </>
    )
  }

  return (
    <div className='graph-card'>
      {  investmentValueByCategoryGraphLoading ?
        <Loader height="25rem" />
        :
        <div className='content'>
          <div className='content-header'>
            <div className='content'>
              <div className='text-view'>
                <span className='text'>Value of Investment Assets by Category</span>
              </div>
              <div className='dropdown'>
                <DropDownIcon />
              </div>
            </div>
          </div>
          <div id="chart" className='investment-assets-value-by-category-view'>
            { graphConfig && graphConfig.options && investmentAssetsValueByCategoryViewGraph.graphType === 'bar' && <ReactApexChart className='investment-assets-value-by-category-chart' options={graphConfig.options} series={graphConfig.series} type='bar' height={300} /> }
            { graphConfig && graphConfig.options && investmentAssetsValueByCategoryViewGraph.graphType === 'area' && <ReactApexChart className='investment-assets-value-by-category-chart' options={graphConfig.options} series={graphConfig.series} type='area' height={300} /> }
          </div>
        </div>
      }
    </div>
  )
}

function useGetInvestedAssetsEvalutationByCateogry(dispatch, user, filterDate, filterCategory, filterEntity, dates, categories, entities, all_time_irr, series, graphCategories, lodash, setInvestmentValueByCategoryGraphLoading) {
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (all_time_irr && all_time_irr.hasOwnProperty('increase') && all_time_irr.hasOwnProperty('percentage') && all_time_irr.hasOwnProperty('value') && isInitialMount.current) {
      fetchData(dispatch, user)
      isInitialMount.current = false;
    }
  }, [dispatch, user, all_time_irr]);

  useEffect(() => {
    if (all_time_irr && all_time_irr.hasOwnProperty('increase') && all_time_irr.hasOwnProperty('percentage') && all_time_irr.hasOwnProperty('value')) {
      setInvestmentValueByCategoryGraphLoading(true)
      fetchData(dispatch, user)
    }
  }, [dispatch, user, filterDate, filterCategory, filterEntity]);

  async function fetchData(dispatch, user, retry=0) {
    let params = {
      organization: getOrganizationIdOFLoggedUser(user),
      quarter_dropdown: filterDate ? lodash.find(dates, function(date) { return date.value === filterDate }).label : null,
      category: filterCategory ? lodash.find(categories, function(category) { return category.value === filterCategory }).label : null,
      entity: filterEntity ? filterEntity : null
    }
  
    const { payload } = await dispatch(getInvestedAssetsValueByCategory(params))

    if(payload && payload.response && payload.response.status === 401 && retry < process.env.REACT_APP_MAX_RETRY_FOR_REFRESH_TOKEN) {
      dispatch(getNewAccessToken());
      fetchData(dispatch, user, retry + 1);
    }
    else if(payload && payload.status === 200) {
      setInvestmentValueByCategoryGraphLoading(false)
    }
  }
}

export default InvestmentValueByCategoryGraph