import React from 'react'

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import {styled} from "@mui/material/styles";


const CustomCircularProgress = styled(CircularProgress)(({speed}) => ({
    animationDuration: `${speed || 1.4}s`, // Default speed is 1.4s (Material-UI's default)
}));

function Loader(props) {
    const speed = props.speed || 3.4;

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: props.width ? props.width : '100%',
                height: props.height,
            }}
        >
            <CustomCircularProgress color='inherit' speed={speed}/>
        </Box>
    )
}

export default Loader